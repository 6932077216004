import React from "react";
import Carousel from "react-bootstrap/Carousel";

export default function CustomerTestimoni() {
  return (
    <section className="parallax-section clearfix testimonial_template" id="section-88">
      <div className="mid-content">
        <h2>
          <span>TESTIMONIAL</span>
        </h2>
        <div className="parallax-content"></div>
        <div className="testimonial-listing clearfix wow fadeInUp">
          <div className="testimonial-slider slick-initialized slick-slider">
            <Carousel
              indicators={false}
              // prevIcon={<i class="fa fa-angle-left slick-arrow"></i>}
              // nextIcon={<i class="fa fa-angle-right slick-arrow"></i>}
            >
              <Carousel.Item>
                <div className="testimonial-list">
                  <div className="testimonial-content">
                    <p>
                      Tumbasin sangat membantu dalam operasional rumah makan kami. Tumbasin membantu
                      menyediakan barang-barang yang dibutuhkan dapur kami dengan harga yang sangat
                      murah serta kualitas barang yang terjamin kesegarannya.
                    </p>
                  </div>
                  <h3>Angga Wijaya – Pengelola Ayam Geprek Juara</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/angga-150x150.jpeg"
                      alt="Angga Wijaya – Pengelola Ayam Geprek Juara"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list ">
                  <div className="testimonial-content">
                    <p>
                      Bagi saya sendiri Tumbasin membantu dalam kebutuhan belanja mingguan keluarga
                      kami. Disamping saya sendiri yang kerepotan yang sebelumnya harus belanja di
                      Pasar sendiri, tapi dengan adanya Tumbasin saya tidak perlu lagi ke Pasar
                      untuk belanja.
                    </p>
                  </div>
                  <h3>Arnila Bramanto – Dokter Spesialis</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/arnila-150x150.jpeg"
                      alt="Arnila Bramanto – Dokter Spesialis"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list ">
                  <div className="testimonial-content">
                    <p>
                      Awalnya saya belum percaya dengan aplikasi ini, tetapi setelah saya mencoba
                      untuk order pertama kali, selang beberapa menit ada notifikasi dari adminnya
                      kalo barangnya akan diantar besok pagi. Setelah itu, saya sering menggunakan
                      aplikasi Tumbasin untuk belanja kebutuhan bulanan di rumah.
                    </p>
                  </div>
                  <h3>Bu Fifi – Bisnis Owner</h3>
                  <div className="testimonial-image">
                    <img src="./index_files/fifi-150x150.jpeg" alt="Bu Fifi – Bisnis Owner" />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list">
                  <div className="testimonial-content">
                    <p>
                      Aplikasi ini sangat recomended, karena sayakan seorang Ibu Rumah Tangga yang
                      setiap hari harus mengurus pekerjaan rumah apalagi saya juga masih punya anak
                      balita yang tidak bisa ditinggal sendiri di rumah. Jadi setiap kali mau masak
                      tidak perlu repot pergi meninggalkan si buah hati sendiri. Harga murah dan
                      kualitas barang terpercaya!
                    </p>
                  </div>
                  <h3>Bu Ernest – Ibu Rumah Tangga</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/ernest-150x150.jpeg"
                      alt="Bu Ernest – Ibu Rumah Tangga"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list">
                  <div className="testimonial-content">
                    <p>
                      Saya tahu Tumbasin dari postingan di sosial media. Saya cari tahu dan mencoba
                      aplikasi ini. Ternyata barang belanjaan yang diantarkan sesuai dengan harapan.
                      Barang dan harganyapun bagus tidak kalah dengan supermarket.
                    </p>
                  </div>
                  <h3>Bu Dini – Administrasi Bank Muamalat</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/dini-150x150.jpeg"
                      alt="Bu Dini – Administrasi Bank Muamalat"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list">
                  <div className="testimonial-content">
                    <p>
                      Tumbasin sangat membantu dalam operasional rumah makan kami. Tumbasin membantu
                      menyediakan barang-barang yang dibutuhkan dapur kami dengan harga yang sangat
                      murah serta kualitas barang yang terjamin kesegarannya.
                    </p>
                  </div>
                  <h3>Angga Wijaya – Pengelola Ayam Geprek Juara</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/angga-150x150.jpeg"
                      alt="Angga Wijaya – Pengelola Ayam Geprek Juara"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list ">
                  <div className="testimonial-content">
                    <p>
                      Bagi saya sendiri Tumbasin membantu dalam kebutuhan belanja mingguan keluarga
                      kami. Disamping saya sendiri yang kerepotan yang sebelumnya harus belanja di
                      Pasar sendiri, tapi dengan adanya Tumbasin saya tidak perlu lagi ke Pasar
                      untuk belanja.
                    </p>
                  </div>
                  <h3>Arnila Bramanto – Dokter Spesialis</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/arnila-150x150.jpeg"
                      alt="Arnila Bramanto – Dokter Spesialis"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list">
                  <div className="testimonial-content">
                    <p>
                      Awalnya saya belum percaya dengan aplikasi ini, tetapi setelah saya mencoba
                      untuk order pertama kali, selang beberapa menit ada notifikasi dari adminnya
                      kalo barangnya akan diantar besok pagi. Setelah itu, saya sering menggunakan
                      aplikasi Tumbasin untuk belanja kebutuhan bulanan di rumah.
                    </p>
                  </div>
                  <h3>Bu Fifi – Bisnis Owner</h3>
                  <div className="testimonial-image">
                    <img src="./index_files/fifi-150x150.jpeg" alt="Bu Fifi – Bisnis Owner" />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list">
                  <div className="testimonial-content">
                    <p>
                      Aplikasi ini sangat recomended, karena sayakan seorang Ibu Rumah Tangga yang
                      setiap hari harus mengurus pekerjaan rumah apalagi saya juga masih punya anak
                      balita yang tidak bisa ditinggal sendiri di rumah. Jadi setiap kali mau masak
                      tidak perlu repot pergi meninggalkan si buah hati sendiri. Harga murah dan
                      kualitas barang terpercaya!
                    </p>
                  </div>
                  <h3>Bu Ernest – Ibu Rumah Tangga</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/ernest-150x150.jpeg"
                      alt="Bu Ernest – Ibu Rumah Tangga"
                    />
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testimonial-list">
                  <div className="testimonial-content">
                    <p>
                      Saya tahu Tumbasin dari postingan di sosial media. Saya cari tahu dan mencoba
                      aplikasi ini. Ternyata barang belanjaan yang diantarkan sesuai dengan harapan.
                      Barang dan harganyapun bagus tidak kalah dengan supermarket.
                    </p>
                  </div>
                  <h3>Bu Dini – Administrasi Bank Muamalat</h3>
                  <div className="testimonial-image">
                    <img
                      src="./index_files/dini-150x150.jpeg"
                      alt="Bu Dini – Administrasi Bank Muamalat"
                    />
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
