import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { blogs } from "../data/blogs";
import Footer from "./component/Footer";
import Header from "./component/Header";

function BlogDetail() {
  const [content, setContent] = useState({
    id: null,
    title: "",
    creator: "",
    image: "",
    content: ``,
  });

  const blogId = useParams().id;

  const filterContent = () => {
    const blogContent = blogs.filter((blog) => blog.id === +blogId)[0];
    setContent(blogContent);
  };

  useEffect(() => {
    filterContent();
    window.scrollTo(0, 0);
  }, [blogId]);

  return (
    <div id="page" className="hfeed site">
      <Header />

      <div id="content" className="site-content no-slider" style={{ marginTop: 80 }}>
        <div className="mid-content clearfix">
          <div id="primary" className="content-area">
            <main id="main" className="site-main" role="main">
              <article
                id="post-3474"
                className="article-wrap post-3474 post type-post status-publish format-standard has-post-thumbnail hentry category-blog"
              >
                <div className="entry-thumb">
                  <img src={content.image} alt={content.title} />
                </div>

                <header className="entry-header">
                  <div className="entry-meta">
                    <span className="posted-on">
                      <time className="entry-date published" datetime={content.created_at}>
                        <span className="posted-day">
                          {moment(new Date(content.created_at)).format("DD")}
                        </span>
                        <span className="posted-month">
                          {moment(new Date(content.created_at)).format("MMM")}
                        </span>
                      </time>
                    </span>
                  </div>
                  <div className="byline">
                    By <span className="author vcard">{content.creator}</span>
                  </div>
                  <h1 className="entry-title">{content.title}</h1>
                </header>
                <div
                  dangerouslySetInnerHTML={{ __html: content.content }}
                  className="entry-content"
                ></div>

                <footer className="entry-footer">
                  <i className="fa fa-folder-open" aria-hidden="true"></i>
                  <Link to={`/blogs`}>Blog</Link>
                </footer>
              </article>

              <nav className="navigation post-navigation" role="navigation" aria-label="Posts">
                <h2 className="screen-reader-text">Post navigation</h2>
                <div className="nav-links">
                  <div className="nav-previous">
                    <Link to={`/blog/3`}>
                      <i className="fa fa-hand-o-left"></i>Beberapa Manfaat Kopi Bagi Tubuh
                    </Link>
                  </div>
                  <div className="nav-next">
                    <Link to={`/blog/1`}>
                      Tumbasin Blog Competition 2019<i className="fa fa-hand-o-right"></i>
                    </Link>
                  </div>
                </div>
              </nav>

              <div id="comments" className="comments-area">
                <div id="respond" className="comment-respond">
                  <h3 id="reply-title" className="comment-reply-title">
                    Leave a Reply{" "}
                    <small>
                      <a
                        rel="nofollow"
                        id="cancel-comment-reply-link"
                        href="/web/20200315132042/http://tumbasin.id/ulasan-singkat-ikan-kerapu-dari-ciri-ciri-sampai-harga-ikan-kerapu/#respond"
                        style={{ display: "none" }}
                      >
                        Cancel reply
                      </a>
                    </small>
                  </h3>
                  <form
                    action="/"
                    method="post"
                    id="commentform"
                    className="comment-form"
                    novalidate
                  >
                    <p className="comment-notes">
                      <span id="email-notes">Your email address will not be published.</span>{" "}
                      Required fields are marked <span className="required">*</span>
                    </p>
                    <p className="comment-form-comment">
                      <label for="comment">Comment</label>{" "}
                      <textarea
                        readOnly
                        id="comment"
                        name="comment"
                        cols="45"
                        rows="8"
                        maxlength="65525"
                        required="required"
                      ></textarea>
                    </p>
                    <p className="comment-form-author">
                      <label for="author">
                        Name <span className="required">*</span>
                      </label>{" "}
                      <input
                        id="author"
                        name="author"
                        type="text"
                        readOnly
                        size="30"
                        maxlength="245"
                        required="required"
                      />
                    </p>
                    <p className="comment-form-email">
                      <label for="email">
                        Email <span className="required">*</span>
                      </label>{" "}
                      <input
                        id="email"
                        name="email"
                        type="email"
                        readOnly
                        size="30"
                        maxlength="100"
                        aria-describedby="email-notes"
                        required="required"
                      />
                    </p>
                    <p className="comment-form-url">
                      <label for="url">Website</label>{" "}
                      <input id="url" name="url" type="url" readOnly size="30" maxlength="200" />
                    </p>
                    <p className="comment-form-cookies-consent">
                      <input
                        id="wp-comment-cookies-consent"
                        name="wp-comment-cookies-consent"
                        type="checkbox"
                        readOnly
                      />{" "}
                      <label for="wp-comment-cookies-consent">
                        Save my name, email, and website in this browser for the next time I
                        comment.
                      </label>
                    </p>
                    <p className="form-submit">
                      <input name="submit" type="submit" id="submit" className="submit" readOnly />{" "}
                      <input type="hidden" name="comment_post_ID" id="comment_post_ID" readOnly />
                      <input type="hidden" name="comment_parent" id="comment_parent" readOnly />
                    </p>
                  </form>{" "}
                </div>
              </div>
            </main>
          </div>

          <div id="secondary" className="widget-area" role="complementary">
            <aside id="search-2" className="widget widget_search">
              <form role="search" method="get" className="search-form" action="/">
                <label>
                  <span className="screen-reader-text">Search for:</span>
                  <input
                    readOnly
                    type="search"
                    className="search-field"
                    placeholder="Search …"
                    name="s"
                  />
                </label>
                <input type="submit" className="search-submit" value="Search" />
              </form>
            </aside>{" "}
            <aside id="recent-posts-2" className="widget widget_recent_entries">
              {" "}
              <h2 className="widget-title">
                <span>Recent Posts</span>
              </h2>{" "}
              <ul>
                {blogs.map((blog, index) => (
                  <li key={index}>
                    <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
                  </li>
                ))}
              </ul>
            </aside>
            <aside id="recent-comments-2" className="widget widget_recent_comments">
              <h2 className="widget-title">
                <span>Recent Comments</span>
              </h2>
              <ul id="recentcomments"></ul>
            </aside>
            <aside id="categories-2" className="widget widget_categories">
              <h2 className="widget-title">
                <span>Categories</span>
              </h2>{" "}
              <ul>
                <li className="cat-item cat-item-21">
                  <Link to={`/#section-265`}>Belanja</Link>
                </li>
                <li className="cat-item cat-item-23 current-cat">
                  <li className="cat-item cat-item-21">
                    <Link to={`/blogs`}>Blog</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-19">
                  <li className="cat-item cat-item-21">
                    <Link to={`/`}>Home</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-20">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-78`}>Layanan Kami</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-22">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-79`}>Promo</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-24">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-88`}>Testimonial</Link>
                  </li>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BlogDetail;
