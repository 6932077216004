export const help = [
  {
    topic: "Tentang Kami",
    qna: [
      {
        q: "Apa Tumbasin.id?",
        a: `<p>Tumbasin.id adalah sebuah jasa layanan yang membantu membelanjakan dan mengantarkan bahan-bahan kebutuhan harian dari Pasar Tradisional ke depan pintu rumah Anda.</p>`,
      },
    ],
  },
  {
    topic: "Operasional",
    qna: [
      {
        q: "Pasar mana saja yang sementara bekerjasama dengan Tumbasin.id?",
        a: `
        <p>Per Desember 2021 pihak Tumbasin.id sudah bekerjasama dengan Pasar Tradisional dari 15 Kota yang telah bergabung dalam Aplikasi Tumbasin. <p>
        
        <ol>
        <li>Semarang terdapat 8 Pasar Tradisional</li>
        <li>Bandar Lampung terdapat 3 Pasar Tradisional</li>
        <li>Bandung terdapat 1 Pasar Tradisional </li>
        <li>Bekasi terdapat 5 Pasar Tradisional</li>
        <li>DKI Jakarta terdapat 12 Pasar Tradisional</li>
        <li>Karawang terdapat 4 Pasar Tradisional</li>
        <li>Bogor terdapat 4 Pasar Tradisional</li>
        <li>Depok terdapat 2 Pasar Tradisional</li>
        <li>Tangerang Selatan terdapat 2 Pasar Tradisional</li>
        <li>Surakarta terdapat 2 Pasar Tradisional</li>
        <li>D.I. Yogyakarta terdapat 4 Pasar Tradisional</li>
        <li>Malang terdapat 10 Pasar Tradisional</li>
        <li>Kab. Malang terdapat 10 Pasar Tradisional</li>
        <li>Kendari terdapat 5 Pasar Tradisional</li>
        <li>Makassar terdapat 2 Pasar Tradisional</li>
        </ol>
        `,
      },
      {
        q: "Operasional Tumbasin.id sudah ada di kota mana saja?",
        a: `
        <span>Saat ini kami sudah melayani 15 Kota di Indonesia <span>
        <ul>
        <li>
            <span>Sumatera</span>
            <ol>
                <li>Bandar Lampung</li>
            </ol>
        </li>
        <li>
        <span>Jawa Barat & DKI Jakarta</span>
        <ol>
            <li>DKI Jakarta</li>
            <li>Bekasi</li>
            <li>Depok</li>
            <li>Bogor</li>
            <li>Tangerang Selatan</li>
            <li>Karawang</li>
            <li>Bandung</li>
        </ol>
        </li>
        <li>
        <span>Jawa Tengah & DI Yogyakarta</span>
        <ol>
            <li>Semarang</li>
            <li>Surakarta</li>
            <li>D.I Yogyakarta</li>
        </ol>
        </li>
        <li>
        <span>Jawa Timur</span>
        <ol>
            <li>Kota/Kab. Malang</li>
        </ol>
        </li>
        <li>
        <span>Sulawesi</span>
        <ol>
            <li>Kendari</li>
            <li>Makassar</li>
        </ol>
        </li>
        </ul>
        `,
      },
    ],
  },
  {
    topic: "Harga dan Transaksi",
    qna: [
      {
        q: "Berapa ongkos kirimnya?",
        a: `<p>Ongkos kirim Tumbasin.id saat ini adalah di 20 KM pertama Rp10.000, diatas 20 KM ada tambahan Rp5.000 untuk Kota Semarang. Dan untuk kota di luar Semarang ongkos pengiriman jauh atau dekat Rp15.000</p>`,
      },
    ],
  },
  {
    topic: "Pesanan",
    qna: [
      {
        q: "Seberapa cepat Tumbasin.id mengantarkan pesanan?",
        a: `<p>Pesanan sekarang kami antar besok pagi. Batas pemesanan sampai pukul 00.00 WIB selebihnya akan dikirim lusa. Pengantaran dikirim mulai dari jam 06.00 - 10.00 pagi</p>`,
      },
      {
        q: "Apa yang dimaksud dengan waktu pengantaran?",
        a: `<p>Pengiriman dilakukan dari jam 06.00 - 10.00 pagi</p>`,
      },
      {
        q: "Bagaimana jika stok barang yang saya pesan habis?",
        a: `<p>Jika terdapat barang habis di Pasar Tradisional, pihak Customer Care akan menghubungi Anda baik via chat maupun telephone.</p>`,
      },
      {
        q: "Apakah harga barang di Tumbasin.id berbeda dengan harga di Pasar Tradisional?",
        a: `<p>Harga yang terpublikasi pada situs atau aplikasi sama dengan harga pasar, dan harga dapat berubah tanpa pemberitahuan terlebih dahulu. Jika anda menemukan ada barang yang anda pikir harganya keliru, silakan hubungi kami! Kami melayani pembatalan barang yang memiliki harga yang keliru dikarenakan error.</p>`,
      },
      {
        q: "Bagaimana saya mengecek status pemesanan saya?",
        a: `<p>Setiap Anda belanja Customer Care kami akan mengkonfirmasi terlebih dahulu ke Anda untuk memastikan belanjaan Anda siap kami belanjakan. Dan Anda bisa melihat status pesanan pada fitur Transaksi pesanan.</p>`,
      },
      {
        q: "Bagaimana cara edit atau membatalkan pesanan?",
        a: `<p>Anda bisa menghubungi Customer Care kami, jika terdapat produk yang akan Anda edit atau batalkan.</p>`,
      },
      {
        q: "Apa kebijakan Tumbasin.id terkait pembatalan pesanan?",
        a: `<p>Untuk berbagai alasan teknis, anda tidak dapat membatalkan pesanan ketika pesanan tersebut telah dibelanjakan.</p>`,
      },
      {
        q: "Bagaimana jika saya ingin melaporkan masalah terhadap pesanan saya?",
        a: `<p>Jika anda memiliki masalah terhadap pesanan anda baik dari kwalitas produk maupun dalam hal pengantaran, silakan hubungi Customer Care.</p>`,
      },
      {
        q: "Bagaimana melihat struk pembelanjaan saya?",
        a: `<p>Anda akan menerima nota belanjaan dari Tim Pengantaran kami, saat belanjaan sampai di tangan Anda.</p>`,
      },
      {
        q: "Bagaimana jika saya ingin mengembalikan kantong belanja Tumbasin.id?",
        a: `<p>Kami mengimbau anda untuk mengembalikan kantong Tumbasin.id yang masih dalam kondisi baik untuk digunakan pada pembelanjaan anda berikutnya. Namun, silakan saja jika anda ingin menggunakannya untuk keperluan pribadi.</p>`,
      },
      {
        q: "Bagaimana jika saya ingin mereturn barang?",
        a: `<p>Jika pada pesanan Anda terdapat barang yang keliru, maka Anda dapat menolak barang tersebut ketika kurir kami mengantarkannya ke rumah Anda, atau anda dapat melaporkannya ke Customer Care kami.</p>`,
      },
      {
        q: "Siapa yang akan memilihkan pesanan saya?",
        a: `<p>Kami memiliki personal shopper yang akan berbelanja dan driver yang akan mengantar pesanan ke tempat Anda. Semua shopper dan driver kami sudah terlatih untuk memilihkan produk terbaik untuk kamu.</p>`,
      },
      {
        q: "Siapa yang akan mengantarkan pesanan saya?",
        a: `<p>Pesanan kamu akan diantar oleh driver kami.</p>`,
      },
      {
        q: "Saya punya pertanyaan lebih lanjut untuk Tumbasin.id!",
        a: `<p>Silakan hubungi kami di tumbassemarang@gmail.com atau bisa langsung menghubungi Customer Care kami ke 082242861268. Kami siap melayani anda!</p>`,
      },
    ],
  },
  {
    topic: "Hapus Akun",
    qna: [
      {
        q: "Bagaimana Cara Menghapus Akun Tumbasin ?",
        a: `<p>Hubungi customer service Tumbasin lewat alamat email tumbassemarang@gmail.com atau chat WhatsApp di nomor 0822-4286-1268, dan sampaikan bahwa anda ingin membatalkan akun beserta alasannya.</p>`,
      },
    ],
  },
];
