import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer id="colophon" className="site-footer">
      <div className="top-footer footer-column-1">
        <div className="mid-content">
          <div className="top-footer-wrap clearfix">
            <div className="footer-block">
              <aside id="custom_html-2" className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget"></div>
              </aside>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-footer">
        <div className="mid-content clearfix">
          <div className="copy-right">© 2020 Tumbasin.id</div>

          <div className="site-info">
            <Link to="/">Tumbasin.id</Link>
            <Link style={{ margin: "0px 10px" }} to="/help">
              Bantuan
            </Link>
            <Link style={{ margin: "0px 10px" }} to="/privacy-policy/">
              Kebijakan Privasi
            </Link>
            <Link to="/term-of-use/">Syarat Penggunaan</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
