import React from "react";
import { Link } from "react-router-dom";
import TumbasinLogo from "../../images/tumbasin_logos.png";

function Header() {
  return (
    <header id="masthead" className="logo-side">
      <div className="mid-content clearfix">
        <div className="flex-box">
          <Link to={`/`}>
            <img style={{ width: 255 }} src={TumbasinLogo} alt="Tumbasin.id" />
          </Link>
          <nav id="site-navigation" className="main-navigation">
            <div className="menu-toggle">Menu</div>

            <ul className="nav single-page-nav">
              <li>
                <a href="/#section-78">LAYANAN KAMI</a>
              </li>
              <li>
                <a href="/#section-265">PRODUK KAMI</a>
              </li>
              <li>
                <a href="/#section-79">PROMO</a>
              </li>
              <li>
                <a href="/#section-81">BLOG</a>
              </li>
              <li>
                <a href="/#section-88">TESTIMONIAL</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="social-icons" style={{ marginTop: "-46.5px" }}>
        <a
          href="https://www.facebook.com/tumbasin.id/"
          className="facebook"
          data-title="Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-facebook"></i>
          <span>Facebook</span>
        </a>

        <a
          href="https://www.linkedin.com/company/pt-tumbas-sinergi-indonesia/about/"
          className="linkedin"
          data-title="Linkedin"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-linkedin"></i>
          <span>Linkedin</span>
        </a>

        <a
          href="https://www.instagram.com/tumbasin.id/"
          className="instagram"
          data-title="instagram"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-instagram"></i>
          <span>instagram</span>
        </a>
      </div>
    </header>
  );
}

export default Header;
