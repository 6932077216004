import blogCompetitionImage from "../images/Slider-44-–-3-e1575124569949.png";
import ikanKerapuImage from "../images/imagesa-640x300.jpg";
import coffeeBlogImage from "../images/images-37-670x300.jpg";
import coffeeRecipeImage from "../images/images-29-640x300.jpg";
import monthlyShoppingImage from "../images/images-23-678x300.jpg";
import salmonBlogImage from "../images/images-20-640x300.jpg";
import broccoliBlogImage from "../images/broccoli.jpg";
import broccoliBlogImage2 from "../images/images-16-580x300.jpg";
import meatBlogImage from "../images/meat.jpg";
import squidBlogImage from "../images/daging-cumi-644x300.jpg";

export const blogs = [
  {
    id: 1,
    title: "Tumbasin Blog Competition 2019",
    creator: "admin",
    image: blogCompetitionImage,
    created_at: "2019-12-05T05:52:42+00:00",
    content: `
                  <h3>Hi Bloggers Semarang!</h3>
                  <p>
                    Tumbasin mengadakan kontes menulis blog 2019 dan menangkan
                    <strong>hadiah jutaan rupiahnya</strong>.
                  </p>
                  <p>
                    <em>
                      Terkadang kesibukan bekerja ataupun jarak tempuh yang lumayan jauh membuat
                      kita kesulitan untuk berbelanja kebutuhan untuk memasak sehari-hari. Ceritakan
                      tentang aktivitas keseharianmu dan bagaimana tumbasin dapat memberi kemudahan
                      untuk memenuhi kebutuhan berbelanja harian untuk kebutuhan keluarga
                      sehari-hari maupun usahamu.
                    </em>
                  </p>
                  <p>
                    <em>
                      Tidak semua orang bisa dengan mudah membeli kebutuhan sehari-harinya, tetapi
                      kini dengan Tumbasin
                      <strong>#BerbelanjaDiPasarTradisionalJadiMudah</strong>
                    </em>
                  </p>
                  <p>
                    Ayo bikin tulisan terbaikmu disini, cukup klik
                    <strong>link pendaftaran dibawah ini</strong>:
                  </p>
                  <h3>
                    <strong>
                      <span >
                        ==&gt; DAFTAR SEKARANG
                      </span>
                    </strong>
                  </h3>
                  <p>&nbsp;</p>
                  <p>Berikut beberapa ketentuan Tumbasin Blog Competition</p>
                  <h4>
                    <strong>Syarat dan Ketentuan</strong>
                  </h4>
                  <ul>
                    <li>
                      Peserta adalah <strong>Warga Negara Indonesia (WNI)</strong> yang berdomisili
                      di Indonesia dan memiliki KTP
                    </li>
                    <li>
                      Terbuka untuk umum <strong>(Laki-laki dan Perempuan)</strong>
                    </li>
                    <li>
                      Periode lomba: <strong>3 Desember – 25 Januari 2020</strong>, pengumuman
                      pemenang pada <strong>31 Januari 2020</strong>
                    </li>
                    <li>
                      Peserta diharuskan <strong>memiliki blog pribadi</strong> yang telah aktif
                      minimal 6 bulan, baik untuk platform web 2.0 ataupun domain sendiri
                    </li>
                    <li>
                      Platform user-generated blog seperti Kompasiana, Kumparan, Medium, IDNTimes,
                      atau forum seperti Kaskus, Indowebster <strong>TIDAK diperbolehkan</strong>
                    </li>
                    <li>
                      Peserta dapat mendaftarkan <strong>lebih dari 1 artikel</strong> pada blog
                      yang berbeda. Akan tetapi hanya bisa memenangkan 1 hadiah jika terpilih
                    </li>
                    <li>
                      Peserta <strong>mendaftarkan URL artikel</strong> yang akan diikutkan lomba
                      melalui formulir registrasi yang telah tersedia sebagai tanda keikutsertaan
                    </li>
                    <li>
                      Peserta
                      <strong>
                        wajib menginstal Aplikasi Tumbasin
                        <a target="_blank" href="http://bit.ly/DownloadTumbasin">
                          (Link Tumbasin)
                        </a>
                      </strong>
                      yang akan menjadi persyaratan saat mengisi form registrasi
                    </li>
                    <li>
                      Peserta <strong>wajib mem-follow</strong>, akun media sosial Tumbasin
                      <a target="_blank" href="https://www.instagram.com/tumbasin.id/?hl=en">
                        <strong>@tumbasin.id</strong>
                      </a>
                    </li>
                    <li>
                      Lomba ini tidak dipungut biaya apapun<strong> (GRATIS)</strong>
                    </li>
                    <li>
                      Hasil keputusan juri terhadap pemenang adalah mutlak dan tidak bisa diganggu
                      gugat
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <h4>
                    <strong>Syarat dan Ketentuan Artikel Blog</strong>
                  </h4>
                  <ul>
                    <li>
                      Tema Artikel:
                      <em>“Ceritakan pengalaman kamu berbelanja dengan aplikasi tumbasin.id”</em>
                    </li>
                    <li>
                      Panjang artikel <strong>minimal 500 kata</strong>, ditulis menggunakan
                      <strong>Bahasa Indonesia</strong>
                    </li>
                    <li>
                      Artikel berisi ulasan
                      <strong>pengalaman menggunakan aplikasi Tumbasin.id</strong> untuk berbelanja
                      serta belum dipublikasikan di media/platform manapun
                    </li>
                    <li>Judul artikel harus mengandung kata Tumbasin</li>
                    <li>
                      Peserta wajib memberikan link ==&gt;&nbsp;
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=id.tumbasin.app">
                        <strong>
                          https://play.google.com/store/apps/details?id=id.tumbasin.app
                        </strong>
                      </a>
                    </li>
                    <li>
                      Dengan <strong>Anchor Text</strong> di bawah ini (pilih salah satu)
                    </li>
                  </ul>
                  <ol>
                    <li>Tumbasin</li>
                    <li>Aplikasi Belanja Pasar Tradisional Online</li>
                  </ol>
                  <ul>
                    <li>
                      Peserta yang sudah mendaftarkan blog-nya,
                      <strong>tidak boleh menghapus artikel dan juga link yang tercantum</strong>
                    </li>
                    <li>
                      &nbsp;Peserta wajib <strong>mempromosikan hasil tulisan</strong> melalui akun
                      media sosial yang dimiliki (<strong>Instagram (Jangan di Privasi)</strong>),
                      dan men-tag akun
                      <a target="_blank" href="https://www.instagram.com/tumbasin.id/?hl=en">
                        <strong>@tumbasin.id</strong>
                      </a>
                      dan menyertakan hashtag&nbsp;
                      <em>
                        <strong>#BerbelanjaDiPasarTradisionalJadiMudah</strong>
                        <strong>#PasarTradisionalOnline #JualSayurOnline</strong>
                      </em>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <h4>
                    <strong>Hadiah Pemenang</strong>
                  </h4>
                  <ul>
                    <li>
                      Juara 1 : <strong>Uang Tunai Rp 1.500.000</strong>
                    </li>
                    <li>
                      Juara 2 : <strong>Uang Tunai Rp 800.000</strong>
                    </li>
                    <li>
                      Juara 3 : <strong>Uang Tunai Rp 300.000</strong>
                    </li>
                    <li>
                      Juara Harapan (untuk 5 orang) : Masing-masing mendapat
                      <strong>voucher belanja Rp 50.000/orang</strong>
                    </li>
                  </ul>
                  <h5></h5>
                  <h5>Ayo ikuti kompetisi ini dan menangkan hadiahnya!</h5>
                  <h1>
                    <span >
                      <strong>DAFTAR SEKARANG</strong>
                    </span>
                  </h1>
    `,
  },
  {
    id: 2,
    title: "ULASAN SINGKAT IKAN KERAPU DARI CIRI-CIRI SAMPAI HARGA IKAN KERAPU",
    creator: "admin",
    image: ikanKerapuImage,
    created_at: "2019-11-27T05:58:23+00:00",
    content: `<p>
        <span style={{ fontWeight: 400 }}>
          Tidak dapat dipungkiri bahwa ikan kerapu merupakan salah satu jenis ikan
          yang laris dikonsumsi oleh masyarakat Indonesia. Bahkan, saat ini Anda
          akan mendapati bahwa ada banyak negara di luar sana yang menginginkan
          untuk impor ikan ini. Alhasil ikan ini diekspor ke negara tersebut dengan
          demand yang tidak sedikit.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Ada banyak sekali hal yang perlu diperhatikan mengenai ikan kerapu.
          Seperti ciri-ciri dari ikan, variasi jenis, serta harga dari ikan kerapu.
          Semakin banyak informasi yang Anda ketahui, maka hal tersebut akan semakin
          bagus untuk anda yang ingin berbelanja ikan kerapu.
        </span>
      </p>
      <h4>
        <b>Ciri Ciri Ikan Kerapu</b>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          Ciri-ciri ikan Kerapu yang pertama adalah ikan ini memiliki bentuk badan
          yang pipih dan mulut lebar. Badan yang pipih dari ikan ini berpostur kecil
          dan berukuran panjang. Rahang atas dan bawah dari gigi ikan ini memiliki
          gigi yang lancip dan kuat.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Sedangkan mulut lebar yang dimaksud adalah mulut bagian bawah panjangnya
          melebih yang bagian atas. Anda juga akan dapat mengidentifikasi ikan ini
          berdasarkan bentuk siripnya yang bundar. Sirip tersebut berjumlah tunggal
          dan memanjang
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Bagian perut dari ikan ini tepat berada di bawah sirip bagian dada dari
          ikan. Badan dari ikan ini terdaoar sirip kecil dengan bentuk sisik
          stenoid. Setiap jenis dari ikan kerapu selalu memenuhi kriteria tersebut
          dan tidak banyak ikan yang memiliki ciri-ciri ini, terutama bagian
          rahangnya.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Setelah mengetahui dari karakteristik khas ikan kerapu, maka Anda sudah
          dapat membedakan ikan ini dibandingkan dengan variasi ikan lainnya.
          Kemampuan untuk dapat mengenali ikan ini berdasarkan ciri-cirinya sangat
          penting.
        </span>
      </p>
      <h4>
        <b>Variasi Ikan Kerapu</b>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          Ikan Kerapu juga memiliki banyak jenis. Setiap jenis sudah pasti akan
          memiliki detail yang berbeda-beda. Anda yang akan memulai bisnis budidaya
          ikan kerapu harus mampu untuk mengenali variasi dan masing-masing
          ciri-ciri dari variasi tersebut.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Hal ini akan berpengaruh dari harga penjual dan bibit dari ikan tersebut.
          Bahkan, ikan ini juga tidak hanya ditemukan di wilayah perairan Indonesia
          saja. Anda juga bisa menemukan jenis dari ikan ini di belahan dunia
          lainnya.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>1. Ikan kerapu batik</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Jenis ikan kerapu yang pertama adalah Ikan Kerapu Batik. Ikan ini biasa
          ditemukan di wilayah perairan Indo-Pasifik. Sebagian besar dari ikan ini
          juga senang menempati area terumbu karang, karena memang sebagian besar
          ikan bertempat tinggal di perairan dangkal.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Jenis ikan yang satu ini kini dilindungi, karena terancam kepunahan akibat
          ulah manusia yang melakukan penangkapan masal dan tidak diimbangi dengan
          pelestarian lingkungan. Padahal ikan yang satu ini juga dapat
          dibudidayakan dengan menggunakan karamba.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>2. Ikan kerapu bebek</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Jenis berikutnya adalah Kerapu bebek. Ikan ini juga mengalami ancaman
          kepunahan akibat penyebab yang sama dengan ikan kerapu batik. Saat ini
          ikan kerapu ini juga mulai di budidayakan untuk mencegahnya dari
          kepunahan. Jenis ini dapat berukuran paling tidak setengah meter.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>3. Ikan kerapu kertang</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Ikan Kerapu Kertang merupakan jenis ikan Kerapu yang banyak ditemukan pada
          kedalaman 100 meter dari permukaan. Ikan ini memiliki fisik didominasi
          dengan warna hitam dan terdapat totol berwarna putih. Anda akan dapat
          dengan mudah menemukan jenis ini di seluruh wilayah perairan Indonesia dan
          Pasifik. Ikan yang satu ini bisa hidup di ekosistem muara.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Selain dari jenis yang sudah disebutkan, masih terdapat banyak sekali ikan
          ini yang belum disebutkan jenisnya. Misalkan saja Ikan Kerapu Lumpur, Ikan
          Kerapu Macan, Ikan Kerapu Balong, Ikan Kerapu Sunuk, Ikan Kerapu Kustang,
          Ikan Kerapu Cantik, dan Ikan Kerapu Biasa. Banyak sekali kan jenisnya?
        </span>
      </p>
      <h4>
        <b>Memilih Ikan Kerapu Segar</b>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          Untuk mengetahui tanda-tanda ikan segar atau tidak, diharuskan untuk
          memperhatikan secara teliti dari segala sisi. Fresh atau tidaknya ikan
          bisa dilihat dengan kasat mata. Berikut adalah ciri-ciri ikan segar yang
          sehat untuk dikonsumsi,
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>1. Mata Ikan</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Ikan yang segar dapat terlihat dari mata ikan. Mata ikan yang segar
          memiliki mata yang terang, bening, dan cembung. Jika dilihat secara kasat
          mata, bisa terlihat jelas dan mengkilap. Sangat terlihat perbedaannya
          dengan ikan yang tidak segar, biasanya ikan yang sudah tidak segar
          cenderung matanya buram dan terlihat pucat.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>2. Insang Ikan</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Jangan lupa untuk mengecek insang ikan, pastikan insang berwarna merah dan
          terasa kenyal jika disentuh. Karena insang yang segar memiliki warna merah
          tua, cerah atau terang, serta tidak berbau asing. Ciri-ciri insang yang
          tidak segar biasanya berbaca pucat atau gelap dan kotor, keabu-abuan,
          berlendir, serta berbau busuk.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>3. Kulit &amp; Lendir Ikan</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Ada lagi cara termudah untuk kamu dalam mengecek kesegaran ikan, yaitu
          kulit ikan. Kulit ikan yang segar akan berwarna segar cemerlang dan tidak
          pudar. Selain itu, ikan yang segar juga memiliki daging yang elastis dan
          tidak mengalami perubahan ketika disentuh. Karena pada dasarnya ikan
          memiliki sisik yang kasar dan bercorak sesuai dengan masing-masing jenis
          ikan itu sendiri. Sisik ikan akan melekat kuat dan sisik utuh tertutup
          lendir atau belum banyak yang hilang. Lendir ikan juga berpengaruh dalam
          mengecek kesegaran ikan, lendir ikan segar akan terlihat cemerlang,
          transparan dan homogen. Jika lendir berwarna kekuningan dan atau berwarna
          putih susu maka bisa dikatakan itu tergolong kategori tidak segar.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>4. Aroma &amp; Sirip Ikan</span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Aroma ikan juga bisa menunjukkan kesegaran ikan yang dijual di pasar. Ikan
          yang segar akan memiliki aroma yang cenderung lebih amis. Sedangkan ikan
          yag tidak segar, memiliki aroma amis yang sedikit dan juga terasa asam.
          Kembali melihat pada fisik ikan, sirip yang kuat menandakan ikan tersebut
          masih dalam keadaan fresh (segar). Badan ikan yang terasa keras dan tidak
          terdapat luka-luka pada tubuhnya menjadi salah satu tanda ikan tersebut
          fresh.
        </span>
      </p>
      <h4>
        <b>Harga Ikan Kerapu Pasaran</b>
      </h4>
      <p>
        <span style={{ fontWeight: 400 }}>
          Anda pasti penasaran dengan harga jual dari ikan kerapu dewasa di pasaran.
          Setiap jenis sudah pasti memiliki kisaran harga yang berbeda. Anda yang
          akan berbisnis budidaya ikan ini perlu untuk mengetahui kisaran harga
          tersebut.
        </span>
      </p>
      <table style={{ height: 553 }} width="604">
        <tbody>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Jenis Ikan Kerapu</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Harga ikan kerapu </span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Tikus</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 420.000</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Merah</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 375.000</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Batu</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 230.000</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Macan</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 120.000</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Kertang</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 175.000</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Lumpur</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 150.000</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Kustang</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 405.000</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 400 }}>Ikan Kerapu Cantang</span>
            </td>
            <td>
              <span style={{ fontWeight: 400 }}>Rp 310.000</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <span style={{ fontWeight: 400 }}> </span>
      </p>
      <p>
        <span style={{ fontWeight: 400 }}>
          Itulah beberapa gambaran umum tentang ikan kerapu dan hal &#8211; hal yang
          harus diperhatikan dalam berbelanja untuk mendapatkan ikan fresh yang
          berkualitas. Dengan ikan yang berkualitas tinggi, akan membuat masakan
          olahan ikan terasa lebih nikmat. Ikan yang tidak fresh, akan membuat
          masakan terasa tidak terlalu nikmat dilidah karena rasanya yang berkurang
          akibat tidak fresh. Pastikan ikan menjadi salah satu menu wajib yang
          disajikan untuk keluarga, karena manfaat dari kandungan gizi yang ada pada
          ikan sangat penting untuk kesehatan dan baik bagi masa pertumbuhan anak.
          Ayo, gemar makan ikan karena makan ikan itu sehat.
        </span>
      </p>`,
  },
  {
    id: 3,
    title: "Beberapa Manfaat Kopi Bagi Tubuh",
    creator: "admin",
    image: coffeeBlogImage,
    created_at: "2019-11-26T03:56:19+00:00",
    content: `
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Kopi adalah sejenis minuman yang berasal dari proses pengolahan dan ekstraksi
                      biji tanaman kopi. Kata kopi sendiri berasal dari bahasa Arab: qahwah yang
                      berarti kekuatan, karena pada awalnya kopi digunakan sebagai makanan berenergi
                      tinggi. Kata qahwah kembali mengalami perubahan menjadi kahveh yang berasal
                      dari bahasa Turki dan kemudian berubah lagi menjadi koffie dalam bahasa
                      Belanda. Penggunaan kata koffie segera diserap ke dalam bahasa Indonesia
                      menjadi kata kopi yang dikenal saat ini.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Secara umum, terdapat dua jenis biji kopi, yaitu kopi arabika (kualitas
                      terbaik) dan kopi robusta. Sejarah mencatat bahwa penemuan kopi sebagai
                      minuman berkhasiat dan berenergi pertama kali ditemukan oleh Bangsa Etiopia di
                      benua Afrika sekitar 3000 tahun (1000 SM) yang lalu. Kopi kemudian terus
                      berkembang hingga saat ini menjadi salah satu minuman paling populer di dunia
                      yang dikonsumsi oleh berbagai kalangan masyarakat. Indonesia telah mampu
                      memproduksi lebih dari 400 ribu ton kopi per tahunnya. Di samping rasa dan
                      aromanya yang menarik, kopi juga dapat menurunkan risiko terkena penyakit
                      kanker, diabetes, batu empedu, dan berbagai penyakit jantung (kardiovaskuler).
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Kafein adalah senyawa kimia alkaloid dikenal sebagai trimetilsantin dengan
                      rumus molekul C8H10N4O2. Jumlah kandungan zat kafein yang terdapat pada kopi
                      adalah antara 1 hingga 1,5%.
                    </span>
                  </p>
                  <h4>
                    <b>Memacu Otak Berpikir Positif</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Minum kopi ternyata bisa membantu kita melihat sisi baik kehidupan. Sebuah
                      penelitian menunjukkan bahwa kafein dapat membantu orang mengenali kata-kata
                      positif.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Peneliti dari Jerman mengungkapkan dengan mengkonsumsi kafein, setara dengan
                      dua hingga tiga cangkir kopi, seseorang mampu melihat kata-kata positif lebih
                      cepat dalam serangkaian percobaan. Psikolog juga menemukan tidak memiliki efek
                      serupa ketika disodori kata-kata negatif.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Penelitian menunjukkan orang dapat mengenali kata-kata positif, gambar serta
                      suara lebih cepat dibanding segala hal negatif. Para peneliti bereksperimen,
                      ternyata kafein bermanfaat mengarah ke respon lebih cepat juga lebih sedikit
                      membuat kesalahan dalam tugas-tugas mental sederhana.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Psikolog eksperimental, Lars Kuchinke beserta timnya dari Universitas Ruhr,
                      Jerman meminta 66 relawan untuk memutuskan secepat mungkin apakah bentukan
                      huruf yang ditampilkan pada komputer adalah kata-kata. Separuh relawan itu
                      diberi tablet dengan kandungan 200 miligram kafein atau setara dengan dua
                      hingga tiga cangkir kopi. Sementara sisanya diberi pil laktosa. Mereka
                      diberikan tablet itu 30 menit sebelum pengujian.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Hasilnya, relawan yang mengonsumsi tablet dengan kandungan kafein tujuh persen
                      lebih akurat dalam mengenali kata-kata positif daripada kata-kata lainnya.
                      Kuchinke berpendapat, mungkin ini karena kafein berfungsi merangsang bagian
                      otak yang terhubung oleh segala hal positif.
                    </span>
                  </p>
                  <h4>
                    <b>Mencegah Timbulnya Penyakit Jantung atau Stroke</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Kandungan dalam kopi dapat menghidarkan dari kita dari penyakit serangan
                      jantung bahkan hingga stroke, Sebuah studi atas lebih dari 83.000 wanita
                      berusia lebih dari 24 tahun menunjukkan mereka yang minum dua sampai tiga
                      cangkir kopi sehari memiliki risiko terkena stroke 19% lebih rendah
                      dibandingkan mereka yang tidak minum kopi. Studi terhadap sejumlah pria di
                      Finlandia menunjukkan hasil sama.
                    </span>
                  </p>
                  <h4>
                    <b>Menghambat Penurunan Fungsi Kognitif</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Studi atas 4.197 wanita serta 2.820 pria di Perancis menunjukkan bahwa meminum
                      setidaknya tiga cangkir kopi sehari dapat menghambat penurunan fungsi kognitif
                      otak akibat penuaan hingga 33 persen pada wanita. Namun, manfaat serupa tidak
                      ditemukan pada pria. Hal ini mungkin karena wanita lebih peka terhadap kafein.
                    </span>
                  </p>
                  <h4>
                    <b>Mencegah Penyakit Kanker &amp; Diabetes</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Dari hasil riset oleh peneliti di seluruh dunia didapatkan hasil bahwa
                      penyakit kanker hati, kanker payudara serta kanker usus besar dapat dicegah
                      dengan cara mengonsumsi kopi. Studi penelitian lain telah menemukan bahwa kopi
                      dapat mengurangi tingkat gula darah pada pasien diabetes. Karena kandungan
                      kopi dapat meningkatkan laju metabolisme tubuh, selain itu manfaat kopi juga
                      dapat mengurangi risiko penyakit serius seperti batu empedu, penyakit
                      Parkinson, penyakit jantung, sirosis hati dll.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Dua puluh studi di seluruh dunia menunjukkan bahwa kopi mengurangi risiko
                      diabetes tipe 2 hingga 50%. Para peneliti menduga penyebabnya adalah kandungan
                      asam klorogenik dalam kopi berperan memperlambat penyerapan gula dalam
                      pencernaan. Asam klorogenik juga merangsang pembentukan GLP-1, zat kimia yang
                      meningkatkan insulin (hormon yang mengatur penyerapan gula ke dalam sel-sel).
                      Zat lain dalam kopi yaitu trigonelin (pro vitamin B3) juga diduga membantu
                      memperlambat penyerapan glukosa.
                    </span>
                  </p>
                  <h4>
                    <b>Mencegah Resiko Kanker Mulut dan Melindungi Gigi</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Kandungan senyawa dalam kopi bermanfaat mencegah terjadinya resiko kanker
                      mulut, membatasi pertumbuhan sel kanker. Selain itu kopi mengandung sifat anti
                      bakteri &amp; anti-perekat yang sangat baik untuk menyembuhkan berbagai
                      masalah berkaitan dengan kesehatan mulut, termasuk gigi berlubang, pembentukan
                      plak dan infeksi gusi.
                    </span>
                  </p>
                  <h4>
                    <b>Pembangkit Stamina dan Energi Ekstra</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Mekanisme kerja zat kafein dalam tubuh bersaing dengan fungsi adenosin dalam
                      tubuh kita. Adenosin sendiri merupakan senyawa yang terdapat dalam sel otak
                      berfungsi membuat orang cepat tertidur. Kandungan kafein dapat memperlambat
                      gerak sel-sel tubuh sehingga tubuh tidak mudah lelah/mengantuk, muncul
                      perasaan segar, mata terbuka lebar, detak jantung lebih kencang, serta naiknya
                      tekanan darah.
                    </span>
                  </p>
                  <h4>
                    <b>Mengurangi Rasa Sakit Kepala</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Menurut Seimur Damond, M.D, dari Chicago’s Diamond Hadche Clinic. Bahwa
                      kandungan kafein pada kopi dapat mengurangi derita sakit kepala. Penderita
                      sakit kepala atau migran ringan terbukti dapat disembuhkan dengan meminum
                      secangkir kopi pekat.
                    </span>
                  </p>
                  <h4>
                    <b>Mengatasi Perubahan Suasana Hati &amp; Depresi</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Minum kopi secara teratur sesuai dengan porsinya dapat memaksimalkan kerja
                      otak lebih baik. Kandungan antioksidan pada kopi dapat menangkal kerusakan sel
                      otak &amp; membantu jaringan saraf untuk bekerja lebih baik. Sedangkan
                      kandungan kafein dalam kopi berfungsi sebagai stimulan tubuh. Hal ini dapat
                      merangsang indera kita serta meningkatkan laju metabolisme. Sehingga
                      meningkatkan kemampuan dalam berkonsentrasi, mengatasi perubahan suasana hati
                      bahkan depresi.
                    </span>
                  </p>
                  <h4>
                    <b>Manfaat Kopi Untuk Scrub</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Bagi Anda yang memiliki kulit kering akan mendapatkan manfaat maksimum kopi
                      sebagai pengelupas sel kulit mati dan merevitalisasi kulit tanpa membuatnya
                      menjadi lebih kering. Kulit yang kering bisa menyebabkan penuaan dini akan
                      tetapi kopi memiliki sifat anti penuaan yang mencegah masalah kulit tersebut.
                      Aplikasikan 3 sendok teh kopi bubuk halus lembut dicampur dengan 1 sendok teh
                      susu dan buatlah menjadi pasta. Aplikasikan pasta tersebut ke wajah sebagai
                      scrub. Pijatlah seluruh area wajah dalam gerakan melingkar kemudian biarkan
                      sampai mengering. Setelah pasta mengering bilaslah dengan air dingin. Lalu
                      keringkan wajah dengan handuk lembut dengan cara ditepuk-tepuk. Anda juga
                      mengaplikasikan pelembab ke wajah anda untuk mengunci semua kelembaban di
                      kulit.
                    </span>
                  </p>
                  <h4>
                    <b>Manfaat Kopi Untuk Masker Wajah</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Kopi bubuk yang dikombinasikan dengan coklat, susu bubuk dan molase sebagai
                      masker wajah akan membantu mengangkat dan mengencangkan kulit kendur.
                      Membersihkan wajah dan tidak kurang sebagai krim anti mikroba.
                    </span>
                  </p>
                  <h4>
                    <b>Manfaat Kopi Untuk Kulit Kepala</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Perlu anda ketahui bahwa kopi ternyata juga bermanfaat untuk kulit kepala
                      serta bagus untuk rambut. Properti antioksidan yang dikandungnya bisa mencegah
                      rambut mengalami kerontokan. International Journal of Dermatolog menunjukkan
                      bahwa kafein pada kopi dapat merangsang pertumbuhan folikel kecil di kulit
                      kepala pada pria yang mulai mengalami kerontokan rambut, seperti dilansir
                      Livestrong. Menurut para peneliti, dibutuhkan lotion topikal yang kaya kafein
                      yang nantinya dioleskan pada kulit kepala untuk memblokir efek dari DHT yang
                      merusak. Karena pada umumnya kebotakan yang dialami laki-laki adalah akibat
                      karena folikel rambut terpapar oleh DHT terlalu banyak.
                    </span>
                  </p>
                  <h4>
                    <b>Manfaat Kopi Untuk Body Message</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Anda bisa mencampur kopi dengan kakao butter atau mentega biasa yang akan
                      menjadi campuran pijat yang ideal. Resep ini apabila diaplikasikan akan baik
                      pada kulit dan menghaluskan kulit. Memelihara kulit, melembabkan dan mencegah
                      keriput.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Manfaat Kopi Untuk Manicure dan Pedicure Bubuk kopi aromatik sangat baik dalam
                      membersihkan tumit retak dan siku hitam. Gosokkan pasta dan pijatlah selama
                      beberapa menit yang akan menghapus semua kotoran di area tersebut dan membuat
                      kulit halus.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Mungkin itulah salah satu dari sekian banyak manfaat kopi yang baik untuk
                      tubuh kita, semoga aartikel ini bermanfaat dan terima kasih.
                    </span>
                  </p>
    `,
  },
  {
    id: 4,
    title: "Aneka Resep Membuat Kopi Kekinian",
    creator: "admin",
    image: coffeeRecipeImage,
    created_at: "2019-11-25T15:56:21+00:00",
    content: `
  <p>
                    <span style={{ fontWeight: 400 }}>
                      Gerai kopi di beberapa daerah di indonesia kian menjamur dari hari ke hari.
                      Konsumen pun kian dimanjakan dengan berbagai pilihan kopi. Selain untuk
                      menambah energi di pagi hari, kopi seolah juga jadi minuman wajib saat hang
                      out bersama teman-teman, atau persiapan bila harus bekerja hingga larut.Namun,
                      tentu tak setiap hari para pecinta kopi punya waktu untuk menyambangi gerai
                      kopi kesayangan mereka. Ada kalanya membuat kopi sendiri di rumah pun sudah
                      cukup.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Bagi Anda yang menyukai kopi, yuk kreasikan kopi yang Anda buat di rumah!
                      Pasti bosan kan bila harus minum kopi instan terus-terusan?
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Melalui resep-resep di bawah ini, Anda bisa mengkreasikan kopi instan menjadi
                      berbagai minuman berbahan dasar kopi yang enak! Simak 6 resep kopi ala kafe di
                      bawah ini:
                    </span>
                  </p>
                  <h4 style={{ textAlign: "center" }}>Resep Kopi Cold brew</h4>
                  <p style={{ textAlign: "center" }}>
                    <img
                      className="alignnone size-full wp-image-3429"
                      src="./index_files/images-30.jpg"
                      alt=""
                      width="681"
                      height="450"
                      srcset="
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-30.jpg         681w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-30-300x198.jpg 300w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-30-250x165.jpg 250w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-30-600x396.jpg 600w
                      "
                      sizes="(max-width: 681px) 100vw, 681px"
                    />
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Bila sering berkunjung ke coffee shop, Anda pasti sudah tak asing dengan menu
                      satu ini. Cold Brew adalah metode peracikan kopi dengan cara menyeduhnya
                      dengan air bersuhu ruang atau air dingin. Kemudian, kopi didiamkan dalam air
                      sekitar setengah hari, atau seharian.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>Bahan-bahan:</span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>6 sdt kopi instan</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>600 ml air suhu ruang</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>gula cair sesuai selera</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
                      <span style={{ fontWeight: 400 }}>Cara membuat:</span>
                    </li>
                  </ul>
                  <ol>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Seduh kopi dengan air, lalu aduk hingga rata
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tuang campuran kopi ke dalam botol kaca, lalu diamkan dalam lemari es selama
                        12 jam agar menghasilkan aroma kopi yang kuat
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Setelah 12 jam, keluarkan kopi dan saring ke dalam gelas lain. Bila suka,
                        Anda bisa menambahkan gula cair dan es batu. Cocok untuk diminum saat hari
                        sedang terik!
                      </span>
                    </li>
                  </ol>
                  <h4 style={{ textAlign: "center" }}></h4>
                  <h4 style={{ textAlign: "center" }}>Resep Kopi Espresso Chill</h4>
                  <p style={{ textAlign: "center" }}>
                    <img
                      className="alignnone size-full wp-image-3430"
                      src="./index_files/images-31.jpg"
                      alt=""
                      width="575"
                      height="262"
                      srcset="
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-31.jpg         575w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-31-300x137.jpg 300w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-31-250x114.jpg 250w
                      "
                      sizes="(max-width: 575px) 100vw, 575px"
                    />
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Rasanya yang pahit dengan aroma kopi yang kuat bikin Espresso Chill cocok
                      untuk diminum sebelum memulai kegiatan yang panjang. Dijamin Anda akan terjaga
                      lebih lama setelah meminumnya.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Tak perlu repot memesan Espresso di kafe, Anda dapat mencobanya sendiri dengan
                      bahan-bahan berikut:
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>3 gelas belimbing air dingin</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>2 sdm kopi bubuk instan</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>es batu secukupnya</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>gula cair sesuai selera</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Bahannya mudah, bukan? Cara membuatnya juga gampang, loh! Anda cukup
                      memasukkan semua bahan ke dalam shaker, lalu kocok hingga tercampur rata.
                      Tuang ke dalam gelas, dan Espresso pun siap untuk dinikmati!
                    </span>
                  </p>
                  <h4 style={{ textAlign: "center" }}></h4>
                  <h4 style={{ textAlign: "center" }}>Resep Kopi Caffe Latte</h4>
                  <p style={{ textAlign: "center" }}>
                    <img
                      className="alignnone size-full wp-image-3431"
                      src="./index_files/images-32.jpg"
                      alt=""
                      width="700"
                      height="394"
                      srcset="
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-32.jpg         700w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-32-300x169.jpg 300w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-32-250x141.jpg 250w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-32-600x338.jpg 600w
                      "
                      sizes="(max-width: 700px) 100vw, 700px"
                    />
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Apakah Anda penggemar kopi yang tak terlalu suka pahit? Maka, Caffe Latte ini
                      tepat untuk Anda! Caffe Latte merupakan campuran antara Espresso dan susu,
                      dengan rasa susu yang lebih dominan. Ayo lihat resep sederhananya di bawah
                      ini:
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>Bahan-bahan:</span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>6 sdm kopi instan</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>800 ml susu </span>
                      <i>
                        <span style={{ fontWeight: 400 }}>full cream</span>
                      </i>
                      <span style={{ fontWeight: 400 }}> hangat</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>200 ml cream kental</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>100 gram gula pasir</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>Cara membuat:</span>
                  </p>
                  <ol>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tuangkan bubuk kopi ke dalam susu hangat, lalu aduk hingga rata
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Taruh campuran bubuk kopi dan susu ke dalam mug, lalu sisihkan
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Masukkan cream kental dan gula pasir ke dalam shaker, lalu kocok hingga kaku
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tuangkan campuran cream dan gula ke atas kopi, lalu hias sesuai selera
                      </span>
                    </li>
                  </ol>
                  <h4 style={{ textAlign: "center" }}></h4>
                  <h4 style={{ textAlign: "center" }}>Resep Kopi Avocado Coffee</h4>
                  <p style={{ textAlign: "center" }}>
                    <img
                      className="alignnone size-full wp-image-3432"
                      src="./index_files/images-33.jpg"
                      alt=""
                      width="765"
                      height="401"
                      srcset="
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-33.jpg         765w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-33-300x157.jpg 300w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-33-250x131.jpg 250w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-33-600x315.jpg 600w
                      "
                      sizes="(max-width: 765px) 100vw, 765px"
                    />
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Siapa bilang kopi hanya nikmat bila dicampur cream atau susu? Ternyata,
                      kombinasi kopi dan alpukat juga dapat memberikan rasa baru yang pastinya enak!
                      Avocado Coffee kedengarannya cocok ya diminum saat jam makan siang? Ayo lihat
                      resepnya berikut ini:
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>Bahan-bahan :</span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <i>
                        <span style={{ fontWeight: 400 }}>5 sdm white coffee</span>
                      </i>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>2 sdm susu bubuk</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>2 gelas belimbing air hangat</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>1/2 gelas air suhu ruang</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>1 buah alpukat matang</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Gula sesuai selera</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Es batu secukupnya</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>Cara membuat :</span>
                  </p>
                  <ol>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Masukkan alpukat dengan air dan sedikit gula ke dalam blender. Haluskan.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Tuangkan ke dalam gelas, sisihkan.</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Aduk white coffee dan susu bubuk dengan air hangat, lalu tambahkan gula
                        sesuai selera
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Masukkan campuran white coffee tadi bersama es batu ke dalam shaker, lalu
                        aduk hingga rata
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tuangkan kopi ke dalam gelas berisi jus alpukat.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tambahkan es batu dan gula sesuai selera
                      </span>
                    </li>
                  </ol>
                  <h4 style={{ textAlign: "center" }}></h4>
                  <h4 style={{ textAlign: "center" }}>Resep Coffee and Chocolate</h4>
                  <p style={{ textAlign: "center" }}>
                    <img
                      className="alignnone size-full wp-image-3433"
                      src="./index_files/images-34.jpg"
                      alt=""
                      width="600"
                      height="397"
                      srcset="
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-34.jpg         600w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-34-300x199.jpg 300w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-34-250x165.jpg 250w
                      "
                      sizes="(max-width: 600px) 100vw, 600px"
                    />
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Setelah lelah kerja seharian, enaknya minum yang hangat dan manis, nih! Lebih
                      baik minum kopi atau coklat ya? Daripada bingung memilih, gabungkan saja
                      keduanya! Simak resepnya berikut:
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>1 sachet kopi hitam instan</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>70 gr dark chocolate.</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Gula pasir sesuai selera</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Bubuk susu Milo secukupnya</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>250 ml susu full cream</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>Cara membuat:</span>
                  </p>
                  <ol>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Potong-potong coklat hingga menjadi kecil-kecil, dan taruh dalam mangkuk
                        tahan panas
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Isi panci dengan air hingga setengahnya, lalu panaskan
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Masukkan mangkuk berisi coklat ke dalam panci, usahakan agar air dalam panci
                        tidak melebihi tinggi mangkuk
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Aduk-aduk coklat hingga meleleh dengan api sedang, jaga agar air panci tidak
                        mendidih. Bila airnya mendidih, uap air akan bercampur dengan coklat dan
                        menyebabkan coklat menggumpal. Bila coklat terlanjur terkena air, masukkan
                        sedikit mentega ke dalam coklat saat dilelehkan kembali. Sisihkan coklat
                        yang telah leleh.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Masak susu hingga mendidih, lalu kecilkan api.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Masukan lelehan coklat, bubuk kopi dan gula pasir. Aduk semuanya hingga rata
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tuangkan campuran coklat dan kopi ke dalam gelas, taburi dengan bubuk susu
                        Milo
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tambahkan es krim, atau es batu bila suka
                      </span>
                    </li>
                  </ol>
                  <h4 style={{ textAlign: "center" }}></h4>
                  <h4 style={{ textAlign: "center" }}>Milkshake White Coffee</h4>
                  <p style={{ textAlign: "center" }}>
                    <img
                      className="alignnone size-full wp-image-3434"
                      src="./index_files/images-35.jpg"
                      alt=""
                      width="678"
                      height="452"
                      srcset="
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-35.jpg         678w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-35-300x200.jpg 300w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-35-250x167.jpg 250w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-35-600x400.jpg 600w
                      "
                      sizes="(max-width: 678px) 100vw, 678px"
                    />
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Hanya punya beberapa sachet white coffee di rumah? Dibanding diminum begitu
                      saja, Anda bisa membuat milkshake dengan white coffee tersebut, loh! Bahan dan
                      cara membuatnya mudah dan tidak butuh waktu lama.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>Bahan-bahan</span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>2 bungkus white coffee</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>1/2 gelas air panas</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>2 sdm gula pasir sesuai selera</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>200 ml susu putih cair</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Bagian biskuit Oreo, patahkan kecil-kecil
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>2 sdm gula pasir sesuai selera</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Air es dan es batu secukupnya</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>Cara membuat:</span>
                  </p>
                  <ol>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Seduh white coffee dengan air panas</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Masukkan white coffee, susu cair, gula, es, dan potongan Oreo ke dalam
                        blender dan blender hingga tercampur rata
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tuangkan milkshake white coffee ke dalam gelas, taburi dengan potongan Oreo
                      </span>
                    </li>
                  </ol>
                  <h4 style={{ textAlign: "center" }}></h4>
                  <h4 style={{ textAlign: "center" }}>Resep Minuman kopi Mocca Mint Cappuccino</h4>
                  <p style={{ textAlign: "center" }}>
                    <img
                      className="alignnone size-full wp-image-3435"
                      src="./index_files/images-36.jpg"
                      alt=""
                      width="400"
                      height="300"
                      srcset="
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-36.jpg         400w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-36-300x225.jpg 300w,
                        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-36-250x188.jpg 250w
                      "
                      sizes="(max-width: 400px) 100vw, 400px"
                    />
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Resep yang terakhir yaitu mocca mint cappuccino. Yuk kita simak resep
                      olahanya.
                    </span>
                  </p>
                  <p>
                    <b>BAHAN-BAHAN :</b>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>1 sdm sirup coklat</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>1 sdm cream</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>1 sdt coklat bubuk</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>100 ml whipped cream</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>100 ml espresso</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>200 ml susu mendidih</span>
                    </li>
                  </ul>
                  <p>
                    <b>CARA MEMBUAT MOCCA MINT CAPPUCCINO :</b>
                  </p>
                  <ol>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Tuang ke dalam satu cangkir : sirup coklat, cream, coklat bubuk dan whipped
                        cream
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Selanjutnya tambahkan espresso dan tuangkan susu yang sudah dipanaskan
                        sampai mendidih.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Aduk rata, maka minuman mocca mint cappuccino siap untuk dihidangkan.
                      </span>
                    </li>
                  </ol>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Bahan-bahannya mudah didapat dan mudah dibuat juga, bukan? Rasanya juga mirip
                      dengan buatan kafe-kafe kopi. Kini, minuman Anda semakin beragam, menjamu
                      kawan yang berkunjung pun semakin mudah.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Selain untuk konsumsi pribadi, Anda juga bisa loh mengkomersilkan kopi-kopi
                      buatan Anda, loh! Tak perlu kafe yang besar dan megah, warung kopi kecil di
                      halaman rumah juga bisa jadi tempat yang nyaman bagi konsumen.
                    </span>
                  </p>
  `,
  },
  {
    id: 5,
    title: "Cara Pintar Kelola Belanja Bulanan",
    creator: "admin",
    image: monthlyShoppingImage,
    created_at: "2019-11-21T03:11:11+00:00",
    content: `
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Terkadang pengeluaran terbesar yang kita keluarkan memang banyak dialokasikan
                      untuk belanja. Belanja memang harus dilakukan demi memenuhi kebutuhan yang
                      dibutuhkan setiap orang, mulai dari belanja makanan, pakaian, keperluan kamar
                      mandi, keperluan dapur, dan masih banyak lainnya. Kita sering tidak sadar
                      bahwa banyak keperluan yang sebenarnya tidak terlalu penting, namun tetap kita
                      beli. Padahal menekan pengeluaran yang tidak perlu bisa menghemat belanja
                      bulanan Anda.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Selain keperluan yang kurang penting dan bersifat tidak wajib, Anda juga perlu
                      mewaspadai beberapa keperluan di luar dugaan yang berpotensi menghabiskan uang
                      Anda, seperti biaya listrik, air, telepon, biaya sosial (hadiah dan
                      sumbangan), pakaian, serta hiburan. Lalu bagaimana caranya agar Anda bisa
                      mengelola belanja bulanan dengan tepat? Berikut ini merupakan 10 cara pintar
                      dalam mengelola belanja bulanan yang dapat Anda terapkan dalam kehidupan
                      sehari-hari.
                    </span>
                  </p>
                  <h4>
                    <b>1. Menyusun Daftar Prioritas</b>
                    <span style={{ fontWeight: 400 }}>&nbsp;</span>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Hal pertama yang harus Anda lakukan ialah dengan cara menyusun daftar
                      prioritas kebutuhan. Buat juga rincian biaya yang akan dikeluarkan dari tiap
                      komponen kebutuhan tersebut. Misalnya saja, Anda memiliki prioritas untuk
                      membeli beras seharga Rp100.000, kemudian membeli minyak goreng Rp50.000,
                      membayar listrik Rp100.000, membayar
                    </span>
                    <a target="_blank" href="https://www.cermati.com/artikel/layanan-streaming-film-netflix-merambah-indonesia">
                      <span style={{ fontWeight: 400 }}>berlangganan Netflix</span>
                    </a>
                    <span style={{ fontWeight: 400 }}>
                      sebesar Rp150.000, dan seterusnya. Jangan lupa untuk menempatkan berbagai
                      komponen biaya yang tak terduga dalam urutan terakhir di dalam daftar
                      prioritas tersebut.
                    </span>
                  </p>
                  <h4>
                    <b>2. Menyesuaikan Daftar Prioritas dengan Anggaran</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Setelah menyusun daftar prioritas, Anda bisa menyesuaikan daftar tersebut
                      dengan anggaran yang dimiliki. Kalau ternyata dana tidak mencukupi, itu
                      berarti Anda harus mengurangi beberapa kebutuhan yang dirasa tidak perlu.
                      Setelah menyesuaikan, Anda harus mematuhi daftar tersebut. Jangan sampai ada
                      kebutuhan di luar anggaran lagi. Anda harus bisa mengatakan “tidak” kepada
                      diri sendiri jika ada kebutuhan lainnya yang harus dibeli atau dibayarkan.
                    </span>
                  </p>
                  <h4>
                    <b>3. Memanfaatkan Waktu Diskon</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Seringkali kita memiliki hasrat yang berlebih pada sebuah barang tanpa
                      berpikir sehat. Anda menyukainya, kemudian Anda tidak peduli apakah ada uang
                      atau tidak. Anda langsung saja membeli tanpa pikir panjang. Hal seperti inilah
                      yang sering mengganggu anggaran belanja yang telah dibuat. Agar tidak mudah
                      tergoda, Anda bisa menggunakan beberapa cara. Salah satu caranya dengan
                      memanfaatkan waktu diskon untuk beberapa produk tertentu. Setiap tahunnya
                      pasti banyak toko yang menggelar obral besar-besaran. Toko online juga sering
                      mengadakan promo tersebut untuk menarik banyak pelanggan. Manfaatkan
                      kesempatan ini sebaik mungkin&nbsp; untuk membeli kebutuhan yang memang telah
                      diprioritaskan. Namun Anda juga harus tetap mengingat bahwa barang-barang yang
                      dibeli merupakan barang-barang yang dibutuhkan, bukan diinginkan. Jangan
                      sampai kalap membeli mentang-mentang lagi
                    </span>
                    <Link to="/">
                      <span style={{ fontWeight: 400 }}>ada program diskon</span>
                    </Link>
                    <span style={{ fontWeight: 400 }}>.</span>
                  </p>
                  <h4>
                    <b>4. Memilih Tempat Belanja</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Saat memilih tempat belanja, sebaiknya Anda memilih berdasarkan harga. Jadikan
                      patokan harga sebagai faktor utama Anda dalam memilih tempat belanja demi
                      keselamatan finansial Anda. Tidak perlu membesarkan gengsi hanya karena ingin
                      terlihat ‘wah’, Anda lalu belanja di tempat yang mewah. Barang-barang rumah
                      tangga merupakan kebutuhan yang harus dibeli secara rutin dan berulang tiap
                      bulannya. Apabila Anda membeli di tempat yang mahal, maka selisih harga yang
                      cukup besar akan terasa setelah beberapa bulan Anda belanja.
                    </span>
                    <span style={{ fontWeight: 400 }}>
                      Sebaiknya anda berbelanja di pasar tradisional karena dapat memberikan
                      keringanan yang lebih ringan dibandingkan belanja di supermarket. Pasar
                      tradisional tidak membebani pembeli dengan beragam pajak yang seringkali
                      membuat harga barang-barang menjadi lebih mahal. Selain itu, Anda juga masih
                      bisa menawar harga bahan-bahan yang Anda beli.
                    </span>
                  </p>
                  <h4>
                    <b>5. Membuat Catatan Belanja</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Buatlah catatan belanja selama satu bulan. Barang apa saja yang Anda butuhkan,
                      misalnya beras, minyak, mie instan, telur, dan lainnya. Saat berbelanja,
                      sebaiknya Anda membawa catatan yang berisi daftar barang apa saja yang harus
                      dibeli. Hal ini bertujuan untuk menghemat uang belanja yang akan Anda
                      keluarkan. Biasanya dengan membawa catatan, Anda hanya akan membeli apa saja
                      yang tertera di kertas tersebut.
                    </span>
                  </p>
                  <h4>
                    <b>6. Jangan Mudah Tergiur Iklan</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Anda harus selalu mengingat bahwa Anda membeli sesuatu memang karena Anda
                      membutuhkannya, bukan menginginkannya. Jangan mudah terbujuk dengan iklan.
                      Perlu Anda sadari bahwa iklan memang dibuat agar Anda tertarik untuk membeli,
                      bukan hanya memberi informasi yang bermanfaat bagi Anda. Oleh karena itu,
                      abaikan saja iklan-iklan yang banyak bermunculan di televisi, koran, majalah,
                      maupun media sosial.
                    </span>
                  </p>
                  <h4>
                    <b>7. Mengatasi Kebutuhan Busana serta Aksesori</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Tidak bisa dipungkiri bahwa kebutuhan fesyen memang cukup penting bagi
                      sebagian orang. Mulai dari pakaian, aksesoris, minyak rambut, tas, sepatu,
                      pembersih wajah, lulur, dan masih banyak lainnya. Dengan semua itu, Anda
                      memang bisa mendapatkan penampilan yang lebih
                    </span>
                    <i>
                      <span style={{ fontWeight: 400 }}>fresh </span>
                    </i>
                    <span style={{ fontWeight: 400 }}>
                      dan modis. Namun Anda harus tetap sadar bahwa kebutuhan fesyen tersebut
                      bukanlah kebutuhan utama yang harus Anda beli. Anda harus menekan pengeluaran
                      untuk barang-barang tersebut jika memang tidak dibutuhkan.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Cara agar Anda tidak kebablasan saat berbelanja barang-barang ini adalah
                      bersikap disiplin terhadap diri sendiri. Tanyakan pada diri Anda sendiri,
                      apakah barang-barang tersebut memang Anda butuhkan? Apakah Anda memang pantas
                      mengenakannya? Jika memang perlu dan pantas, berapa kali Anda akan
                      mengenakannya?
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Sikap seperti ini memang sangat diperlukan demi menekan pengeluaran Anda untuk
                      berbelanja. Apabila Anda biasanya membeli pakaian setiap sebulan sekali,
                      sebaiknya mulai sekarang Anda membiasakan untuk membeli pakaian selama dua
                      bulan sekali. Sekali Anda membeli, sebaiknya beli yang kualitasnya benar-benar
                      bagus agar pemakaiannya bisa awet. Anda tidak perlu khawatir tidak terlihat
                      modis karena Anda bisa melakukan cara kreatif seperti memadupadankan seluruh
                      koleksi yang telah Anda miliki. Sehingga tidak akan ada koleksi baju maupun
                      aksesoris yang mubazir tidak dipakai.
                    </span>
                  </p>
                  <h4>
                    <b>8. Memangkas Pengeluaran untuk Hiburan</b>
                  </h4>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Hiburan merupakan salah satu pengeluaran yang sebenarnya tidak terlalu
                      penting, alias tidak wajib. Namun Anda tetap tidak boleh meninggalkannya.
                      Mengapa demikian? Anda tetap memerlukan hiburan sebagai sarana
                    </span>
                    <i>
                      <span style={{ fontWeight: 400 }}>refreshing </span>
                    </i>
                    <span style={{ fontWeight: 400 }}>
                      setelah penat bekerja. Untuk berhemat, Anda hanya tinggal mengganti jenis
                      hiburannya saja. Jika selama ini Anda selalu menonton film-film terbaru atau
                      menonton konser artis-artis tertentu, sekarang saatnya Anda mengganti
                      kebiasaan-kebiasaan tersebut. Anda bisa menggantinya dengan menonton film di
                      rumah melalui komputer, DVD, atau laptop. Masih banyak kan film-film lama yang
                      bagus dan layak ditonton? Anda tidak harus selalu menonton film baru di
                      bioskop. Selain itu, Anda juga bisa mendengarkan berbagai video konser artis
                      di YouTube atau channel lainnya. Hiburan tidak harus selalu mahal kan? Dua
                      kegiatan tersebut juga sudah termasuk hiburan. Menyenangkannya lagi gratis,
                      Anda tidak perlu mengeluarkan banyak uang.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Anggaran hiburan lainnya yang perlu dipangkas adalah makan di luar bersama
                      teman, pacar, atau keluarga. Anda tidak perlu selalu makan di rumah makan atau
                      kafe setiap minggunya. Jadwalkan sesekali saja dalam sebulan. Anda juga harus
                      bisa tegas menolak jika ada penawaran untuk makan di luar. Anda bisa
                      menggantinya dengan makan di beberapa tempat yang memang menawarkan harga
                      murah. Lebih baik lagi jika Anda masak sendiri di rumah. Selain lebih hemat,
                      hal ini juga bisa menambah keakraban dengan teman atau saudara, bukan?
                    </span>
                  </p>
                  <p>
                    <b>Untuk Keuangan yang Sehat</b>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Beberapa cara di atas merupakan cara cerdas dalam mengelola belanja bulanan
                      yang bisa Anda terapkan. Praktekkan semua hal di atas agar kondisi keuangan
                      Anda tetap aman. Ingat, Anda hanya perlu kesadaran diri untuk melakukan
                      semuanya. Apabila Anda telah sadar, Anda pasti akan lebih mudah melakukannya.
                      Memang menahan keinginan untuk membeli barang-barang yang sebenarnya&nbsp;
                      tidak terlalu dibutuhkan, namun hanya diinginkan, memang cukup sulit. Anda
                      hanya perlu memikirkan apa saja impian Anda yang perlu direalisasikan,
                      sehingga hal tersebut bisa mendesak Anda untuk berhemat
                    </span>
                  </p>`,
  },
  {
    id: 6,
    title: "MANFAAT IKAN SALMON DALAM BIDANG KESEHATAN",
    creator: "admin",
    image: salmonBlogImage,
    created_at: "2019-11-19T06:41:35+00:00",
    content: `
    <p>
    <span style={{ fontWeight: 400 }}>
      Ikan salmon termasuk salah satu ikan yang paling bergizi di dunia lho, karena
      merupakan salah satu sumber terbaik asam lemak omega -3, serta kaya akan
      vitamin dan juga protein yang berkualitas tinggi. Asam lemak omega-3 ini
      sangat penting bagi tubuh karena memiliki peranan yang sangat besar dalam
      menjaga kesehatan tubuh, dan merupakan lemak yang sangat istimewa serta
      sehat.&nbsp;
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Namun selain itu, ada manfaat lain yang bisa diberikan melalui ikan yang
      berasal dari Samudra Atlantik utara dan Pasifik.&nbsp; Yuk, langsung saja
      simak ulasannya berikut ini!
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>1. Mengandung Antioksidan Astaxanthin</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Astaxanthin adalah senyawa yang terkait dengan beberapa efek kesehatan yang
      kuat. Sebagai anggota keluarga karotenoid Antioksidan , astaxanthin memberi
      salmon pigmen merahnya.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Astaxanthin tampaknya menurunkan risiko penyakit jantung dengan mengurangi
      oksidasi kolesterol LDL (yang “buruk”) dan meningkatkan kolesterol HDL
      (“baik”). Satu studi menemukan bahwa 3,6 mg astaxanthin setiap hari cukup
      untuk mengurangi oksidasi kolesterol LDL, yang berpotensi mengurangi risiko
      penyakit jantung. Selain itu, astaxanthin diyakini bekerja dengan asam lemak
      omega-3 salmon untuk melindungi otak dan sistem saraf dari peradangan.
      Terlebih lagi, astaxanthin bahkan bisa membantu mencegah kerusakan kulit dan
      membantu Anda terlihat lebih muda.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Dalam sebuah penelitian, 44 orang dengan kulit yang rusak akibat sinar
      matahari yang diberi kombinasi 2 mg astaxanthin dan 3 gram kolagen selama 12
      minggu mengalami perbaikan signifikan pada elastisitas dan hidrasi kulit.
      Salmon mengandung antara 0,4-3,8 mg astaxanthin per 3,5 ons, dengan salmon
      sockeye memberikan jumlah tertinggi.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>
      2. Makan salmon mengurangi risiko depresi
    </span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Otak adalah 60 persen lemak dan sebagian besar adalah asam lemak omega-3 DHA,
      yang sangat penting untuk fungsi otak dan sistem saraf yang sehat. Makan
      salmon secara teratur telah dikaitkan dengan mengurangi risiko dan kejadian
      depresi, permusuhan pada orang dewasa muda dan penurunan kognitif pada orang
      tua.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>
      3. Salmon membantu membangun otak anak-anak
    </span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Makan salmon saat hamil dan menyusui dapat meningkatkan kemampuan belajar dan
      prestasi akademik anak. Salmon mengandung DHA tingkat tinggi (asam
      decosahexaenoic) yang merupakan asam lemak struktural utama di sistem saraf
      pusat dan retina. Memberi makan salmon kepada anak-anak prasekolah juga
      membantu pencegahan ADHD dan bahkan bisa meningkatkan kinerja akademis.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>4. mencegah kerusakan sel</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Seiring dengan banyaknya kandungan vitamin dan protein tinggi, salmon
      merupakan sumber selenium yang sangat baik, mineral ini berfungsi sebagai
      antioksidan dalam tubuh. Hal ini terkait dengan penurunan risiko radang sendi,
      menjaga sistem kekebalan tubuh dan tiroid bekerja dengan baik, dan dapat
      membantu menjaga jaringan tetap sehat dengan mencegah kerusakan sel.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>5. Meningkatkan Otak &amp; Saraf</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Asam lemak omega-3 meningkatkan efisiensi fungsi otak, meningkatkan daya ingat
      dan membuatnya tetap aktif selama jam kerja yang panjang. Seiring dengan asam
      amino, vitamin A, vitamin D, dan selenium, asam lemak ini melindungi sistem
      saraf dari kerusakan yang terkait dengan penuaan, bertindak sebagai
      antidepresan, melemaskan otak dan juga membantu dalam mengobati penyakit
      Alzheimer dan Parkinson. Mungkin itu sebabnya pemakan ikan seharusnya lebih
      cerdas dari pada pemakan daging.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>6. Peningkatan Mood dan Kognisi</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Banyak peneliti menganggap DHA sebagai lemak paling penting yang ditemukan di
      otak manusia, dan konsentrasi asam lemak omega 3 yang tidak biasa dalam salmon
      ini membantu menjelaskan manfaat mendokumentasikan salmon dan asupan ikan
      omega-3 untuk pemikiran dan penurunan risiko. Masalah otak tertentu yang
      menyertai konsumsi ikan omega-3. Asupan omega-3 dan omega-3 mengandung ikan
      dikaitkan dengan penurunan risiko depresi, penurunan risiko permusuhan pada
      beberapa penelitian remaja, dan penurunan risiko penurunan kognitif pada orang
      lanjut usia. Beberapa penelitian telah menunjukkan hubungan antara asupan IQ
      dan omega-3, dan juga antara IQ dan asupan ikan omega-3.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Yang menarik di daerah asupan ikan, DHA, dan fungsi otak ini adalah penemuan
      protin yang relatif baru. Protectins adalah senyawa khusus yang dibuat dari
      DHA dan penelitian pendahuluan telah menunjukkan bahwa mereka memiliki peran
      yang berpotensi penting sebagai molekul peraturan antiinflamasi, terutama bila
      diproduksi oleh jaringan saraf. (Saat protin diproduksi oleh jaringan saraf,
      mereka biasanya disebut “neuroprotectins.”) Periset telah menduga bahwa
      setidaknya beberapa manfaat terkait otak dari asupan ikan omega-3 mungkin
      karena konversi DHA pada ikan ini untuk melindunginya. yang bisa membantu
      mencegah peradangan berlebihan.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>7. Merawat Mata</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Asupan omega-3 dan konsumsi ikan omega-3 dikaitkan dengan penurunan risiko dua
      masalah yang berkaitan dengan mata: degenerasi makula dan mata kering kronis.
      Dalam kasus degenerasi makula (masalah mata kronis di mana bahan di bagian
      tengah retina di bagian belakang bola mata mulai memburuk dan menyebabkan
      hilangnya penglihatan), dua porsi ikan per minggu adalah jumlah yang telah
      ditunjukkan secara signifikan. mengurangi risiko Untuk mengurangi risiko mata
      kering kronis, jumlah asupan ikan omega-3 yang cukup tinggi (2-4 porsi per
      minggu) adalah jumlah minimum yang dibutuhkan, dengan porsi 5-6 mingguan
      menunjukkan penurunan risiko yang lebih besar.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Seperti studi otak tentang asupan ikan omega-3, studi mata kering mulai
      terlihat secara khusus pada neuroprotectins yang dibuat dari DHA pada ikan
      salmon dan ikan omega-3 lainnya. Molekul turunan omega-3 ini dapat membantu
      mencegah mata kering kronis dengan menurunkan tingkat latar belakang
      peradangan di mata.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>8. Kesehatan Jantung</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Menjadi kaya akan asam lemak omega-3, secara teratur mengonsumsi ikan salmon
      dapat membantu mengurangi peradangan sistemik dan risiko pengembangan
      aterosklerosis, hipertensi dan stroke. R egarding dosage, sebuah studi yang
      diterbitkan oleh School of Medicine and Pharmacology (Universitas Western
      Australia) melaporkan: “Otoritas kesehatan saat ini merekomendasikan asupan
      setidaknya dua makanan ikan berminyak per minggu untuk populasi umum yang
      setara dengan sekitar 500 mg per hari asam eicosapentaenoic dan asam
      docosahexaenoic [dua asam lemak omega-3 utama]. Pada pasien dengan penyakit
      jantung koroner, pedoman merekomendasikan 1 g suplemen harian dan pada pasien
      hipertrigliseridemia sampai 4 g per hari. “
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>9. Kulit Sehat</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Karena kadar omega-3 yang luar biasa, mengkonsumsi ikan salmon yang ditangkap
      liar dapat membantu memberikan kulit yang bercahaya dan lebih kenyal. Selain
      itu, antioksidan karotenoid astaxanthin yang ditemukan pada salmon dapat
      sangat mengurangi efek kerusakan radikal bebas , yang menyebabkan penuaan. Dr
      Perricone, MD, dermatolog terkenal di dunia, merekomendasikan pasiennya
      mengkonsumsi salmon yang ditangkap liar tiga kali seminggu untuk mendapatkan
      kulit yang lebih bercahaya.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>10. Pertahanan Kanker</span>
  </h5>
  <p>
    <span style={{ fontWeight: 400 }}>
      Setiap diskusi tentang manfaat kesehatan ikan salmon kaya omega-3 tidak akan
      lengkap tanpa menyebutkan efek berbasis terbukti yang dapat dimiliki oleh
      makanan super ini pada kanker. Dari 2.000+ makalah ilmiah peer-review yang
      membahas asam lemak omega-3 dan kanker, satu poin jelas: Asam lemak omega-3
      dapat memiliki efek mendalam tidak hanya mencegah kanker, namun membantu
      melawan tumor.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Nah, itulah ulasan tentang manfaat kesehatan dari ikan salmon, yang ternyata
      gak hanya lezat saja, namun juga memiliki manfaat yang sangat luar biasa bagi
      kesehatan tubuh. Semoga bermanfaat!
    </span>
  </p>`,
  },
  {
    id: 7,
    title: "ANEKA KREASI OLAHAN BROKOLI YANG SUPER ENAK",
    creator: "admin",
    image: broccoliBlogImage,
    created_at: "2019-11-18T00:40:01+00:00",
    content: `
    <p>
    <span style={{ fontWeight: 400 }}>
      Brokoli dapat diolah dengan cara ditumis, dikukus, atau bahkan digoreng. Anda
      dapat mengolah brokoli menjadi tempura, sup, atau rolade. Meskipun begitu Anda
      harus tetap memperhatikan tingkat kematangan dari brokoli, agar warna hijaunya
      tidak memudar. Warna yang pudar dari brokoli yang dimasak bisa jadi menandakan
      bahwa kandungan nutrisinya hilang, untuk itu jangan memasak brokoli terlalu
      lama. Dan untuk olahannya, berikut resep olahan brokoli yang bisa kalian coba.
    </span>
  </p>
  <h4></h4>
  <h4 style={{ textAlign: "center" }}>
    <span style={{ fontWeight: 400 }}>Brokoli Crispy</span>
  </h4>
  <p>
    <img
      className="size-full wp-image-3233 aligncenter"
      src="./index_files/images-17.jpg"
      alt=""
      width="617"
      height="270"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-17.jpg         617w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-17-300x131.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-17-250x109.jpg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-17-600x263.jpg 600w
      "
      sizes="(max-width: 617px) 100vw, 617px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Makanan berbahan utama brokoli ini cocok lho buat jadi cemilan. Buat kamu yang
      doyan streaming nonton film atau nonton bola, mending masak cemilan ini
      sebentar. Gak susah kok.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Jangan lupa siapkan saus cabai dan saus mayonaisenya. Biar tambah nampol!
    </span>
  </p>
  <p>
    <b>Bahan yang kamu perlukan:</b>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        250 gram brokoli bersih yang sudah dicuci bersih.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>2 siung bawang putih</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Garam secukupnya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>100 gram tepung terigu</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>50 gram tepung beras</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Air secukupnya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Minyak goreng secukupnya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Saus mayonaise</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Saus cabeCara membuat:</span>
    </li>
  </ul>
  <p>
    <b>Cara membuat:</b>
  </p>
  <ol>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Haluskan bumbu seperti garam, merica dan bawang putih.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        &nbsp;Masukan tepung terigu dan beras ke dalam sebuah wadah.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Lalu, masukan bumbu yang telah dihaluskan sebelumnya. Kemudian campurkan
        secara merata.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Setelah tercampur, ambil satu per lima calon adonan lalu pisahkan pada wadah
        lain. Pada wadah yang berisi 1/5 adonan tersebut, tambahkan sedikit air.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Brokoli yang telah dipotong-potong sesuai keinginan, lalu gulingkan ke dalam
        adonan yang berisi air.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Setelah cukup basah, lalu gulingkan pada wadah lainnya yang berisi adonan
        tanpa air. Ulangi terus menerus sampai semua badan brokoli tertutup adonan.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Jangan lupa buat memanaskan minyak pada wajan dengan api sedang.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Kira-kira cukup panas, lalu goreng brokoli tersebut sampai berwarna
        keemasan.
      </span>
    </li>
  </ol>
  <h4></h4>
  <h4 style={{ textAlign: "center" }}>
    <span style={{ fontWeight: 400 }}>Mac and Cheese Broccoli</span>
  </h4>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3234"
      src="./index_files/images-18.jpg"
      alt=""
      width="739"
      height="415"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-18.jpg         739w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-18-300x168.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-18-250x140.jpg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-18-600x337.jpg 600w
      "
      sizes="(max-width: 739px) 100vw, 739px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Buat kamu yang doyan makanan-makanan yang creamy, makanan satu ini cocok
      banget deh. Cara pembuatannya pun enggak susah-susah amat. Bahan-bahan
      tambahannya bisa kamu beli di minimarket terdekat. Buat bareng abang yuk!
    </span>
  </p>
  <p>
    <b>Bahan-bahan:</b>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>250 gram makaroni</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 buah wortel</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 kuntum ukuran kecil brokoli</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1/2 kotak keju biasa</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1/2 kotak keju quick melt</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>3 susu cair dus kecil</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>2 sdm mentega</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 sdm butter</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>2 sendok makan tepung terigu</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>garam secukupnya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>merica secukupnya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>bawang putih bubuk</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>minyak goreng</span>
    </li>
  </ul>
  <p>
    <b>Cara membuat:</b>
  </p>
  <ol>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Rebus makaroni sampai lunak dengan sedikit garam dan minyak goreng agar
        tidak menempel. Lalu tiriskan.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Cincang kasar brokoli dan wortel yang sudah dicuci bersih. Untuk wortel bisa
        juga di parut. Tumis sebentar dengan diberi bumbu garam dan bubuk bawang
        putih. Lalu sisihkan.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Panaskan mentega dan butter kemudian masukan tepung terigu, aduk rata.
        Masukan susu bear brand, aduk terus sampai tidak menggumpal. Berikan bumbu
        garam dan merica.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Masukan makaroni yang sudah direbus serta brokoli dan wortel yang sudah
        ditumis. Aduk rata.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Tambahkan parutan keju cheddar dan quick melt dan sedikit oregano. Aduk
        rata. Masak sampai keju meleleh. Kurang lebih 5 menit.
      </span>
    </li>
  </ol>
  <h4 style={{ textAlign: "center" }}></h4>
  <h4 style={{ textAlign: "center" }}>
    <span style={{ fontWeight: 400 }}>Ayam Brokoli Saus Bombay</span>
    <b></b>
  </h4>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3235"
      src="./index_files/041938700_1450786228-selerasa.jpg"
      alt=""
      width="680"
      height="383"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/041938700_1450786228-selerasa.jpg         680w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/041938700_1450786228-selerasa-300x169.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/041938700_1450786228-selerasa-250x141.jpg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/041938700_1450786228-selerasa-600x338.jpg 600w
      "
      sizes="(max-width: 680px) 100vw, 680px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Selain makanan sebelumnya, ada makanan kebarat-baratan lainnya, yang bisa kamu
      coba resepnya. Ahhh yakin deh makanan ini bikin kamu gak sabar buat
      mencobanya. Yuk langsung ke dapur!
    </span>
  </p>
  <p>
    <b>Bahan-bahan:</b>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>3 buah fillet ayam</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>kecap asin</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 telur kocok</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>secukupnya tepung</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1/2 bawang bombay</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 kuntum brokoli</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>daun bawang</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>kaldu bubuk (sesuai selera)</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>garam secukupnya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>3 sdm air yang dicampur tepung sagu</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>50 ml air</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>lada hitam bubuk scukupnya</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>Cara membuat:</span>
  </p>
  <ol>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Potong ayam filet sesuai selera, tusuk dengan garpu dan rendam dalam kecap
        asin selama 15 menit.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Biar renyah dan tetap cantik, rebus brokoli selama 2 menit pada air yang
        mendidih.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Hal yang dilakukan selanjutnya adalah iris bawang bombay dan daun bawang.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Panaskan 1-2 sdm minyak goren pada wajan. Kemudian tumis bawang bombay dan
        daun bawang kurang lebih 1/2 menit pada api sedang.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Masukan air, dan air campuran sagu. Aduk rata, masukan kaldu bubuk (jika
        pakai), sedikit garam, lada hitam bubuk.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Tes rasa. Jika sudah pas, masukan brokoli, aduk rata. Matikan api.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Keluarkan ayam dari kecap asin, celupkan ke dalam telur lalu balurkan ke
        tepung. Lakukan pada semua fillet ayam.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Panaskan wajan datar dan panggang 5 menit tiap sisi ayam. Masak dengan api
        sedang hingga matang merata.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Susun ayam dan tuangkan kuah bawang bombay dan brokoli diatasnya. Simple
        banget resepnya, sajikan dengan nasi panas. Enak, enak, enak!
      </span>
    </li>
  </ol>
  <h4 style={{ textAlign: "center" }}></h4>
  <h4 style={{ textAlign: "center" }}>
    <span style={{ fontWeight: 400 }}>Tumis brokoi telur asin</span>
  </h4>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3236"
      src="./index_files/images-19.jpg"
      alt=""
      width="550"
      height="270"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-19.jpg         550w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-19-300x147.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-19-250x123.jpg 250w
      "
      sizes="(max-width: 550px) 100vw, 550px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Nah, bagi anda penggemar tumis ataupun masakan yang berbahan dasar brokoli,
      namun disisi lain merasa bosan dengan olahan brokoli yang itu-itu saja, maka
      untuk sekarang ini anda bisa membuat olahan brokoli atau tumis brokoli yang
      berbeda. Dimana tumis brokoli kali ini akan dipadukan dengan telur asin.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Bahan – bahan</span>
  </p>
  <ul>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>300 gram brokoli</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>2 buah cabai merah besar</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>2 butir telur asin matang 100 ml air</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        1 sendok makan minyak goreng untuk menumis
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>BUMBU TUMIS BROKOLI TELUR ASIN</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>2 siung bawang putih</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        1/2 buah bawang bombay Garam secukupnya
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>1/4 sendok teh merica bubuk</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>1 sendok teh maizena dan</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>1 sendok teh air</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Cara membuat tumis brokoli telur asin yang enak dan sederhana, cara menyiapkan
      bahan dan bumbu tumis brokoli telur asin&nbsp;
    </span>
  </p>
  <ol>
    <li>
      <span style={{ fontWeight: 400 }}>
        Sebelum memasak hal pertama yang harus anda lakukan yaitu menyiapkan bahan
        dan bumbu yang dibutuhkan. Dimana langkah pertama yang harus anda lakukan
        yaitu cuci bersih brokoli yang telah anda persiapkan. Lalu potong perkuntum
        brokoli tersebut, potong serong batangnya.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Kemudian, cincang halus bawang putih dan bawang bombay.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Buang biji cabai merah besar. Lalu potong berbentuk kotak kecil.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Kupas telur asin yang telah disiapkan. Lalu cincang kasar.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Larutkan maizena dengan menggunakan 1 sendok teh air.
      </span>
    </li>
  </ol>
  <p>
    <span style={{ fontWeight: 400 }}>
      Cara membuat/memasak tumis brokoli telur asin&nbsp;
    </span>
  </p>
  <ol>
    <li>
      <span style={{ fontWeight: 400 }}>
        Nah, setelah mempersiapkan bahan dan bumbu dengan langkah seperti di atas
        maka untuk sekarang ini anda bisa melanjutkan pada tahap memasak. Dimana
        langkah awal yang harus anda lakukan yaitu, panaskan minyak goreng yang
        telah dipersiapkan. Lalu tumis brokoli dengan ditambahkan 1/2 sendok garam
        sampai brokoli matang. Setelang matang angkat dan sisihkan.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Panaskan kembali minyak goreng. Dimana untuk sekarang ini anda diharuskan
        menumis bawang putih dan bawang bombay yang telah dicincang serta cabai
        merah. Tumis semuanya sampai mengeluarkan aroma harum. Lalu, masukan telur
        asin yang telah dicincang. Aduk sampai merata.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Setelah itu, tambahkan bumbu-bumbu seperti garam dan merica bubuk. Aduk
        kembali sampai merata.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Tuagkan air, masak sampai air mendidih. Lalu masukan larutan maizena. Masak
        sambil diaduk hingga meletup-letup.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Sementara itu, simpan brokoli yang telah ditumis di atas piring saji. Lalu
        siramkan saus telur asin yang telah matang di atas brokoli.
      </span>
    </li>
  </ol>
  <p>
    <span style={{ fontWeight: 400 }}>
      Nah, gimana? Mulai dapat rekomendasi resep makanan buat sehari – hari kan?
      Kali ini tidak cuma lezat, tapi juga enak dan banyak gizinya! Selamat mencoba,
      ya
    </span>
  </p>`,
  },
  {
    id: 8,
    title: "Tips berbelanja sayuran brokoli dan cara menyimpannya",
    creator: "admin",
    image: broccoliBlogImage2,
    content: `
    
    <p>
    <span style={{ fontWeight: 400 }}>
      Brokoli merupakan salah satu sayuran yang dipercaya memiliki segudang manfaat
      untuk kesehatan tubuh.
    </span>
    <span style={{ fontWeight: 400 }}>
      Dimana sayur yang satu ini bisa dikonsumsi mentah, bahkan bisa diproses atau
      diolah menjadi berbagai macam hidangan yang begitu lezat, enak dan nikmat.
      Diketahui sayur yang satu ini sangat ampuh untuk mencegah kanker paru, kanker
      polon, kanker prostat serta kanker perut.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Bukanlah rahasia lagi jika brokoli dikatakan sebagai sayuran yang banyak
      mengandung manfaat. Kerabat cauliflower atau kembang kolo ini kaya akan
      provitamin A, vitamin C serta asam folat. Tidak hanya itu, brokoli juga
      mengandung banyak mineral utama seperti misalkan potasium, kalium, kalsium,
      besi dan selenium. Bahkan senyawa-senyawa lainnya pun bisa kita temukan pada
      sayur yang satu ini.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Meskipun brokoli kaya akan manfaat, tetapi faktanya brokoli juga memiliki
      kekurangan, dimana sayur yang satu ini terkenal tidak bisa tahan lama atau
      mudah busuk dan gampang rusak. Brokoli memang tidak memiliki waktu simpan yang
      cukup panjang, oleh sebab itulah anda harus bisa memilih brokoli dengan tepat
      ketika membelinya baik di pasar atau di supermarket. Hal ini bertujuan agar
      anda tidak terjebak ketika memilihnya, jika anda mampu memilih brokoli dengan
      benar maka hal ini juga bisa membantu brokoli tetap segar dan tahan lama.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Tidak banyak orang yang tahu seperti apa tips memilih dan menyimpan brokoli
      yang baik dan benar, oleh karena itulah tidak sedikit orang yang justru
      asal-asalan ketika memilih atau membelinya. Namun, untuk saat ini anda semua
      jangan merasa khawatir, karena pada kesempatan kali ini kami akan menguraikan
      seperti apa tips memilih brokoli yang baik, segar dan sehat untuk anda semua.
    </span>
    <span style={{ fontWeight: 400 }}> berikut penjelasannya:</span>
  </p>
  <h4>
    <strong>Tips memilih brokoli yang baik dan benar:</strong>
  </h4>
  <ul>
    <li>
      <span style={{ fontWeight: 400 }}>Pilih brokoli berwarna hijau tua</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Jika Anda ingin membekukan brokoli yang segar, renyah, dan enak, tentu saja
      Anda harus menggunakan brokoli yang sangat segar. Ketika Anda membeli brokoli
      di supermarket lokal atau memetiknya langsung dari kebun, akan lebih baik
      mengetahui ciri-ciri brokoli yang segar dan sehat. Dengan begitu, Anda memilih
      brokoli-brokoli terbaik saja. Anda bisa menentukan apakah brokoli segar atau
      tidak dengan memerhatikan bagian kepala brokoli. Kepala brokoli yang segar
      harusnya berwarna hijau tua.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Jangan memilih brokoli dengan kepala yang berwarna/bercorak kekuningan. Sebab,
      ini adalah tanda bahwa brokoli telah melewati masa segarnya dan akan berbunga.
      Brokoli dengan kondisi seperti ini biasanya memiliki tekstur yang agak keras.
    </span>
  </p>
  <ul>
    <li>
      <h5>
        <span style={{ fontWeight: 400 }}>
          Jangan memilih brokoli berkuntum sebesar kepala korek api
        </span>
      </h5>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Hal lain yang harus Anda perhatikan ketika Anda memilih brokoli adalah dengan
      memerhatikan ukuran satuan kuntum brokoli tersebut. Apakah kuntum brokoli
      berukuran kecil dan membaur dengan yang lain, atau berukuran dengan besar dan
      terpisah sendiri-sendiri? Untuk brokoli yang ideal, seharusnya kuntum terlihat
      kecil-kecil dan merata. Ukuran satuan kuntum yang sedikit lebih kecil jika
      dibandingkan dengan kepala korek api menandakan bahwa brokoli berada pada
      kondisi paling segar dan prima.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Namun, tidak berarti bahwa Anda tidak disarankan untuk memilih brokoli yang
      berkuntum sangat kecil. Brokoli berkuntum kecil juga memiliki rasa yang sangat
      lezat. Nyatanya, kebanyakan brokoli beku yang dijual di supermarket berkuntum
      tidak besar.
    </span>
  </p>
  <ul>
    <li>
      <h5>
        <span style={{ fontWeight: 400 }}>
          Pilih brokoli dengan kepala yang kukuh
        </span>
      </h5>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Tekstur brokoli yang Anda pilih sangatlah penting untuk diperhatikan. Brokoli
      terbaik adalah brokoli dengan tekstur renyah ketika digigit, dan brokoli
      terburuk adalah brokoli bertekstur kenyal dan lembut. Periksa tekstur brokoli
      dengan tangan Anda, bisa dengan meremasnya ataupun memutarnya secara perlahan.
      Brokoli yang baik harusnya bertekstur sedikit keras dan kukuh, tetapi tidak
      sampai benar-benar alot.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Jika Anda memutuskan untuk menanam brokoli sendiri, cara memanen brokolinya
      pun juga tak boleh sembarangan, petik brokoli di pagi hari akan jauh lebih
      baik. Ketika Anda membeli brokoli di pasar, brokoli yang disediakan telah
      dipetik dan dicuci untuk Anda sehingga Anda tidak perlu melakukannya lagi.
      Namun, jika menanam brokoli sendiri di rumah, Anda sendiri yang akan
      menentukan “bagaimana” dan “kapan” Anda akan memanen brokoli tersebut. Jadi,
      manfaatkan kesempatan tersebut untuk mendapatkan kesegaran maksimal dari
      brokoli Anda.
    </span>
  </p>
  <h4>Cara menyimpan brokoli</h4>
  <p>
    <span style={{ fontWeight: 400 }}>
      tidak mudah menyimpan sayuran, terutama brokoli. Teksturnya yang
      berkerut-kerut membuat susah membedakan mana brokoli yang masih segar dan mana
      yang tidak. Jika terlalu lama disimpan, kesegaran brokoli akan hilang. Brokoli
      yang tidak segar sudah barang tentu kadar gizinya berkurang. Untuk itu, perlu
      diketahui cara menyimpan brokoli agar kesegarannya tetap terjaga. Jangan
      sampai Anda asyik menikmati sayuran kesukaan tapi tidak ada satupun gizi yang
      bisa diserap tubuh.
    </span>
  </p>
  <h5>
    <span style={{ fontWeight: 400 }}>
      Berikut adalah tujuh cara tepat untuk menyimpan brokoli agar tetap segar.
    </span>
  </h5>
  <ul>
    <li>
      <h5>
        <span style={{ fontWeight: 400 }}>Keluarkan dari plastik</span>
      </h5>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Tak ada kulkas bukan berarti brokoli tak bisa dinikmati. Kalau saat ini Anda
      tidak memiliki kulkas, itu bukan masalah. Keluarkan saja brokoli dari
      plastiknya lalu simpan di tempat yang kering, misalnya di keranjang. Kemudian
      tutup keranjang dengan menggunakan kertas atau kain agar terhindar dari
      jangkauan anak-anak, binatang, atau debu.
    </span>
  </p>
  <ul>
    <li>
      <h5>
        <span style={{ fontWeight: 400 }}>Celupkan air hangat</span>
      </h5>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Brokoli akan tahan lebih lama jika disimpan dalam kulkas. Sebelum dimasukkan
      kulkas, periksa terlebih dahulu brokoli itu. Adakah ulat tersebut. Anda bisa
      lakukan dengan cara memasukkan brokoli dalam air hangat. Celupkan brokoli
      dengan krop mengarah ke bawah. Segera setelah ulat keluar, angkat brokoli dan
      kibaskan.
    </span>
  </p>
  <ul>
    <li>
      <h5>
        <span style={{ fontWeight: 400 }}>Masak segera</span>
      </h5>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Brokoli yang sudah dipetik sebisa mungkin langsung dimasak sehingga Anda tidak
      akan kehilangan nilai gizi akibat sinar matahari. Sekalipun Anda harus
      menyimpannya untuk beberapa waktu, seperti pada poin satu dan dua, brokoli
      akan tetap terjaga kesegarannya dengan catatan jangan terlalu lama di dalam
      kulkas. Apalagi dengan temperatur yang sangat rendah.
    </span>
  </p>
  <ul>
    <li>
      <h5>
        <span style={{ fontWeight: 400 }}>Potong tangkai</span>
      </h5>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Memotong tangkai dapat membuat brokoli tidak tumbuh saat Anda simpan. Jika
      tidak dipotong, dalam sehari brokoli akan mekar dan berwarna kuning! Oleh
      karena itu potong bonggol selebar 4 jari. Itu ukuran maksimal dimana bonggol
      masih boleh menempel pada bagian bawah brokoli. Selain itu, dengan bonggol
      yang pendek, akan memudahkan menyimpan dalam kulkas.
    </span>
  </p>
  <ul>
    <li>
      <span style={{ fontWeight: 400 }}>Bungkus dengan kantong plastik</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Meskipun suhu kulkas bisa kita atur, tidak ada salahnya jika Anda tetap
      memasukkan brokoli ke dalam plastik. Tujuannya agar kelembapan tetap terjaga.
      Kalau dalam satu rak tersebut Anda tidak hanya menyimpan brokoli, berarti
      bahan makanan lain yang ikut tersimpan juga harus dibungkus dengan plastik.
      Supaya sayuran-sayuran di tempat tersebut tidak saling menyerap aroma satu
      sama lain, apalagi kalau salah satunya memiliki aroma yang sangat kuat,
      plastik yang digunakan sebaiknya berwarna hitam. Karena warna hitam kedap
      cahaya, metabolisme brokoli akan melambat sehingga warna hijau dan
      kesegarannya bisa dipertahankan lebih lama.
    </span>
  </p>
  <ul>
    <li>
      <h5>
        <span style={{ fontWeight: 400 }}>Jangan terlalu penuh</span>
      </h5>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>
      Walaupun kulkas Anda berukuran jumbo, usahakan tidak mengisinya hingga penuh.
      Dengan begitu, aliran udara dingin dari satu bahan ke bahan lain bisa lancar
      dan merata.&nbsp;
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Nah…. Itulah beberapa tips memilih serta menyimpan brokoli. Semmoga bermanfaat
      ya
    </span>
  </p>`,
  },
  {
    id: 9,
    title: "Nilai Gizi Serta Manfaat Daging Sapi Bagi Kesehatan",
    creator: "admin",
    image: meatBlogImage,
    created_at: "2019-11-15T06:35:42+00:00",
    content: `
    <p>
    <span style={{ fontWeight: 400 }}>
      Daging sapi adalah salah satu jenis daging yang umum dikonsumsi sehari-hari
      oleh masyarakat Indonesia. Selain rasanya lezat, daging sapi tentunya juga
      menjadi sumber protein yang baik bagi tubuh. Tahukan Anda bahwa konsumsi
      daging sapi memberikan berbagai manfaat untuk tubuh? Jika belum tahu, simak
      paparan tentang kandungan dan manfaat daging sapi berikut ini, yuk!
    </span>
  </p>
  <h4>
    <span style={{ fontWeight: 400 }}>Kandungan Daging Sapi</span>
  </h4>
  <p>
    <span style={{ fontWeight: 400 }}>
      Manfaat daging sapi tentunya tidak terlepas dari kandungan nutrisi yang ada di
      dalamnya. Keunggulan dari kandungan daging sapi adalah kadar sodiumnya yang
      rendah. Selain itu daging sapi juga merupakan sumber dari protein, vitamin B3,
      vitamin B6, fosfor, zinc, selenium, dan vitamin B12.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Sedangkan kekurangannya adalah masih adanya kandungan lemak jenuh dan juga
      kolesterol di dalamnya. Daging sapi juga masih mengandung sejumlah lemak
      trans.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Berikut adalah rincian dari kandungan daging sapi 100 gram dengan kandungan
      90% daging tanpa lemak dan 10% lemak:
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Kalori: 176</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Air: 69,5 g</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Protein: 20 g</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Karbohidrat: 0</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Gula: 0</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Serat: 0</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Lemak: 10 g</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Kolesterol: 65 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Vitamin B2: 0,2 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Vitamin B3: 5,1 g</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Vitamin B6: 0,4 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Folat: 6,0 mcg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Vitamin B12: 2,2 mcg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Zat besi: 2,2 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Magnesium: 20 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Fosfor: 184 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Kalium: 321 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Sodium: 66 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Zinc: 4,8 mg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>·</span>
    <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
    <span style={{ fontWeight: 400 }}>Selenium: 16,6 mcg</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Kandungan daging sapi di atas adalah kandungan daging sapi
    </span>
    <i>
      <span style={{ fontWeight: 400 }}>raw</span>
    </i>
    <span style={{ fontWeight: 400 }}>
      atau mentah, perubahan dapat terjadi pada jumlah nutrisi setelah daging sapi
      diolah.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Selain memiliki kandungan vitamin dan mineral seperti yang sudah disebutkan di
      atas, daging sapi juga memiliki kandungan zat bioaktif dan juga antioksidan
      yang tentunya juga tidak kalah baik untuk kesehatan.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Senyawa yang kandungannya cukup banyak dalam daging sapi meliputi
    </span>
    <i>
      <span style={{ fontWeight: 400 }}>creatine</span>
    </i>
    <span style={{ fontWeight: 400 }}>, </span>
    <i>
      <span style={{ fontWeight: 400 }}>carnosine</span>
    </i>
    <span style={{ fontWeight: 400 }}>, </span>
    <i>
      <span style={{ fontWeight: 400 }}>taurine</span>
    </i>
    <span style={{ fontWeight: 400 }}>, </span>
    <i>
      <span style={{ fontWeight: 400 }}>glutathione</span>
    </i>
    <span style={{ fontWeight: 400 }}>, dan asam linoleat terkonjugasi.</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Manfaat Daging Sapi bagi kesehatan</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Tidak dapat dipungkiri bahwa daging sapi merupakan bahan makanan dengan sumber
      nutrisi yang sangat baik bagi tubuh. Jika melihat dari berbagai kandungan
      daging sapi, berikut adalah berbagai manfaat makan daging sapi yang bisa kita
      dapatkan:
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>1. Mempertahankan massa otot</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Konsumsi daging sapi secara rutin dipercaya dapat membantu mempertahankan
      massa otot. Seiring dengan bertambahnya usia, perubahan bentuk tubuh sulit
      untuk dihindari. Salah satu yang mungkin akan Anda alami adalah sarkopenia
      atau hilangnya massa otot. Kondisi ini pada dasarnya dapat dicegah dengan cara
      latihan kekuatan dan meningkatkan asupan protein. Daging sapi dapat menjadi
      salah satu pilihan sumber protein untuk mencegah kondisi ini.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>2. Meningkatkan kinerja otot</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Daging sapi mengandung senyawa </span>
    <i>
      <span style={{ fontWeight: 400 }}>carnosine</span>
    </i>
    <span style={{ fontWeight: 400 }}> yang sangat penting bagi fungsi otot. </span>
    <i>
      <span style={{ fontWeight: 400 }}>Carnosine</span>
    </i>
    <span style={{ fontWeight: 400 }}>
      dibentuk dalam tubuh dari beta-alanine, asam amino yang banyak ditemukan pada
      ikan dan daging seperti daging sapi. Memenuhi kebutuhan
    </span>
    <i>
      <span style={{ fontWeight: 400 }}>beta-alanine</span>
    </i>
    <span style={{ fontWeight: 400 }}>
      dengan konsumsi daging sapi dapat meningkatkan kadar
    </span>
    <i>
      <span style={{ fontWeight: 400 }}>carnosine</span>
    </i>
    <span style={{ fontWeight: 400 }}>
      dalam otot. Sebaliknya, diet vegetarian dapat menyebabkan kadar
    </span>
    <i>
      <span style={{ fontWeight: 400 }}>carnosine</span>
    </i>
    <span style={{ fontWeight: 400 }}> rendah.</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Kadar </span>
    <i>
      <span style={{ fontWeight: 400 }}>carnosine</span>
    </i>
    <span style={{ fontWeight: 400 }}>
      tinggi dalam otot sering dikaitkan dengan meningkatkan kinerja otot selama
      latihan dan menurunnya risiko kelelahan.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>3. Mencegah anemia</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Salah satu manfaat daging sapi yang paling banyak dipercaya masyarakat adalah
      mencegah anemia, hal ini memang benar adanya.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Anemia defisiensi besi merupakan jenis anemia yang paling umum. Daging sapi
      tidak hanya menyediakan sumber zat besi bagi tubuh, tapi diketahui juga dapat
      membantu penyerapan zat besi yang berasal dari sumber nabati. Maka dari itu,
      makan daging dianggap efektif dalam mencegah anemia defisiensi besi.
    </span>
    <span style={{ fontWeight: 400 }}>
      Konsumsi daging sapi yang cukup dapat mencukupi sel darah merah yang
      dibutuhkan tubuh. Sehingga tubuh menjadi lebih sehat dan anemia dapat
      teratasi. Selain itu zat besi dalam kandungan daging sapi juga memberi nutrisi
      kepada anak sehingga dapat tumbuh dengan baik dan memiliki semangat belajar
      yang tinggi.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>4. Meningkatkan sensitivitas insulin</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Daging sapi juga dipercaya dapat meningkatkan sensitivitas insulin dan
      menurunkan risiko diabetes.
    </span>
    <span style={{ fontWeight: 400 }}>
      Sel-sel syaraf dalam tubuh membutuhkan banyak oksigen.&nbsp;
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Kandungan asam linoleat terkonjugasi dalam daging sapi dapat membantu
      meningkatkan sensitivitas insulin. Selain itu, daging sapi juga mengandung
      senyawa yang disebut L-carnitine. Sebuah penelitian menunjukkan bahwa asupan
      L-carnitine dapat membantu memperbaiki kadar glukosa puasa dan profil
      kolesterol keseluruhan pada pasien diabetes tipe 2.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>5. Membantu menjaga berat tubuh ideal</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Siapa bilang makan daging sapi justru akan meingkatkan berat tubuh? Daging
      sapi justru dapat membantu Anda menjaga berat tubuh ideal.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Manfaat daging sapi untuk menjaga berat tubuh ideal juga didapatkan dari
      kandungan senyawa asam linoleat terkonjugasi dan juga L-carnitine. Sebuah
      studi menunjukkan bahwa konsumsi suplemen L-carnitine dapat memberikan
      penurunan berat tubuh yang lebih signifikan dari kelompok yang tidak
      mengonsumsi. Sedangkan kandungan asam linoleat terkonjugasi dipercaya dapat
      membantu penurunan lemak.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Namun jika ingin menjaga berat tubuh, tentunya daging sapi tidak boleh
      dikonsumsi secara berlebihan dan harus diperhatikan juga pengolahannya.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>6. Menjaga kesehatan jantung</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Hasil dari penelitian tentang manfaat daging sapi untuk kesehatan jantung
      memang sangat beragam.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Beberapa menyebutkan bahwa daging sapi dapat meningkatkan risiko penyakit
      jantung. Bagian dari daging sapi yang dapat meningkatkan risiko penyakit
      jantung adalah kandungan lemak jenuhnya. Maka dari itu, Anda dapat
      mempertimbangkan untuk mengonsumsi daging sapi tanpa lemak yang lebih sehat.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Di sisi lain, daging sapi justru memiliki berbagai kandungan nutrisi yang
      dapat membantu menurunkan risiko penyakit jantung seperti vitamin B kompleks
      dan antioksidan yang terkandung di dalamnya.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>7. Sumber vitamin B kompleks</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Tubuh membutuhkan berbagai jenis vitamin B kompleks untuk dapat bekerja sesuai
      dengan fungsinya.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Daging dapat menjadi salah satu sumber vitamin B kompleks yang baik. Daging
      sapi memiliki kandungan vitamin B12 yang dibutuhkan oleh sistem saraf, vitamin
      B3 yang dapat mendukung sistem pencernaan, dan vitamin B2 yang baik untuk
      kesehatan kulit dan juga mata.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>8. Sumber antioksidan</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Manfaat daging sapi sebagai sumber antioksidan juga tentunya tidak boleh
      ketinggalan. Daging sapi memiliki kandungan glutathione, senyawa ini sering
      disebut sebagai ‘master antioksidan’. Berbagai penelitian telah menunjukkan
      manfaat glutathione sebagai antioksidan mulia dari mencegah penuaan,
      meningkatkan sistem kekebalan tubuh, mengurangi risiko penyakit kronis, hingga
      meningkatkan umur panjang.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>9. Mempercepat penyembuhan luka</span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Protein dalam daging sapi pun bisa mempercepat penyembuhan luka. Salah satu
      penyebab luka infeksi paling umum adalah terhambatnya penyaluran oksigen ke
      jaringan yang rusak. Kekurangan mineral dapat menunda pembentukan dan
      perbaikan jaringan. Mineral berperan penting dalam penyembuhan, karena
      berperan mendistribusikan oksigen lewat darah.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Itu dia berbagai manfaat makan daging sapi yang bisa Anda rasakan. Selalu
      ingat untuk mengonsumsi daging sapi dengan tidak berlebihan dan tetap
      memerhatikan pengolahannya agar nutrisi dalam daging sapi tidak hilang dan
      tergantikan dengan asupan lain yang justru tidak baik untuk tubuh. Selamat
      menikmati!
    </span>
  </p>
    `,
  },
  {
    id: 10,
    title: "Aneka Resep Cumi yang Menggunggah Selera",
    creator: "admin",
    image: squidBlogImage,
    created_at: "2019-11-14T06:14:51+00:00",
    content: `
    <p>
    <span style={{ fontWeight: 400 }}>
      Cumi adalah salah satu jenis seafood yang memiliki banyak manfaat bagi
      kesehatan tubuh manusia. Diantaranya mampu menurunkan kolesterol karena
      kandungan lemak sehatnya dan membangun otot karena protein tinggi yang
      terkandung di dalamnya.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Oleh karena itulah banyak orang yang akhirnya menciptakan olahan resep cumi
      agar lebih bervariatif dan menggugah selera ketika disantap.
    </span>
  </p>
  <h4>
    <span style={{ fontWeight: 400 }}>
      Berikut beberapa aneka olahan cumi yang bisa kalian coba;
    </span>
  </h4>
  <h5 style={{ textAlign: "center" }}>
    <strong>Resep Cumi Oseng Cabai</strong>
  </h5>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3142"
      src="./index_files/052465000_1543264126-resep-cumi-pedas-cabai-hijau.jpg"
      alt=""
      width="680"
      height="383"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/052465000_1543264126-resep-cumi-pedas-cabai-hijau.jpg         680w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/052465000_1543264126-resep-cumi-pedas-cabai-hijau-300x169.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/052465000_1543264126-resep-cumi-pedas-cabai-hijau-250x141.jpg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/052465000_1543264126-resep-cumi-pedas-cabai-hijau-600x338.jpg 600w
      "
      sizes="(max-width: 680px) 100vw, 680px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Resep cumi kedua adalah cumi oseng cabai. Bagi pecinta rasa pedas, bisa
      mencoba menu salah satu olahan berbahan dasar cumi ini. Berikut ini bahan dan
      cara membuat resep cumi oseng cabai:
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Bahan Membuat Resep Cumi Oseng Cabai</span>
  </p>
  <ul>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Cabai rawit 100 gr</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Cumi asin 200 gr</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Cabai merah keriting 5 buah, iris tipis-tipis
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Cabai hijau keriting 5 buah, iris tipis-tipis
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Bawang merah 5 siung, iris tipis-tipis
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Bawang putih 2 siung, iris tipis-tipis
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Tomat ukuran sedang 1 buah, potong dadu
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Kecap manis 1 sdm</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Lengkuas 2 cm, geprek</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Gula pasir secukupnya</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Saus tiram secukupnya</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Garam secukupnya</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Kaldu ayam secukupnya</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>Air 300 ml</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>Cara Membuat Cumi Oseng Cabai</span>
  </p>
  <ol>
    <li>
      <span style={{ fontWeight: 400 }}>
        Siapkan panci atau wajan ukuran sedang. Rebus cumi yang sebelumnya sudah
        dibersihkan sekitar 10 menit. Jika sudah, tiriskan dan potong cumi menjadi 3
        bagian.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Pada wajan yang lain, tumis bawang merah, bawah putih, cabai dan lengkuas
        sampai mengeluarkan aroma yang harum.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Masukkan cumi kemudian beri air, saus tiram, kecap manis, garam, gula dan
        kaldu ayam secukupnya, sesuai dengan selera. Aduk hingga merata dan biarkan
        air sampai menyusut.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Jika sudah menyusut, kemudian masukkan potongan tomat hingga menjadi layu.
        Masak cumi hingga matang.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Hidangkan cumi oseng cabai bersama dengan nasi hangat.
      </span>
    </li>
  </ol>
  <p>
    <span style={{ fontWeight: 400 }}>
      Jika ingin lebih pedas bisa menambahkan cabai sesuai dengan selera, namun
      jangan terlalu banyak. Tambahkan juga petai agar masakan lebih nikmat dan
      lezat.
    </span>
  </p>
  <h5 style={{ textAlign: "center" }}>Resep Cumi Bakar Bumbu Rujak</h5>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3143"
      src="./index_files/520-cumi_bakar_mommyasia-800x450.jpeg"
      alt=""
      width="800"
      height="480"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/520-cumi_bakar_mommyasia-800x450.jpeg         800w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/520-cumi_bakar_mommyasia-800x450-300x180.jpeg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/520-cumi_bakar_mommyasia-800x450-768x461.jpeg 768w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/520-cumi_bakar_mommyasia-800x450-250x150.jpeg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/520-cumi_bakar_mommyasia-800x450-600x360.jpeg 600w
      "
      sizes="(max-width: 800px) 100vw, 800px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Resep cumi yang sangat sederhana selanjutnya adalah resep cumi bakar bumbu
      rujak. Menu ini cukup unik karena menggunakan bumbu yang tidak biasa digunakan
      untuk olahan cumi. Namun jangan salah, meskipun begitu menu ini tidak kalah
      lezat dengan menu olahan cumi yang lain.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Bahan Membuat Cumi Bakar Bumbu Rujak</span>
  </p>
  <ol>
    <li>
      <span style={{ fontWeight: 400 }}>Cumi 8 potong ukuran besat</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>Daun jeruk 3 lbr</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>Santan 500 ml</span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>Air asam jawa 2 sdm</span>
    </li>
  </ol>
  <p>
    <span style={{ fontWeight: 400 }}>Bumbu Yang Dihaluskan</span>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Cabai merah 5 buah</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Bawang putih 3 siung</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Cabai rawit sesuai keinginan dan selera
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Kemiri 3 butir</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Garam 1 sdt</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Gula pasir 1 sdt</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Kunyit 2 cm</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>Cara Membuat Cumi Bakar Bumbu Rujak</span>
  </p>
  <ol>
    <li>
      <span style={{ fontWeight: 400 }}>
        Rebus cumi yang sudah dibersihkan di dalam panci atau wajan ukuran sedang
        bersama dengan santan dan bumbu yang dihaluskan. Jangan lupa tambahkan asam
        jawa agar bumbu rujak semakin mantap.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Dalam memasak gunakan kompor dengan api kecil hingga bumbu meresap kedalam
        cumi. Jika sudah angkat cumi dan tiriskan.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Siapkan tusukkan sate sejumlah cumi yang akan dibakar. Tusukkan satu ekor
        cumi dalam satu tusuk sate.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Siapkan pembakaran bisa menggunakan kompor maupun alat pembakaran yang biasa
        digunakan untuk membakar sate. Olesi cumi dengan sisa bumbu yang masih ada
        kemudian bakar. Balik posisi sate agar matangnya merata dan sesekali oleskan
        kembali dengan bumbu rujak.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Angkat sate cumi apabila sudah tercium aroma bakar. Lepaskan cumi dari
        tusukannya.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Susun pada piring dan sajikan cumi bakar bumbu rujak bersama dengan nasi
        hangat.
      </span>
    </li>
  </ol>
  <h5 style={{ textAlign: "center" }}>
    <strong>Resep Cumi Basah Balado</strong>
  </h5>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3144"
      src="./index_files/images-12.jpg"
      alt=""
      width="590"
      height="367"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-12.jpg         590w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-12-300x187.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-12-250x156.jpg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-12-480x300.jpg 480w
      "
      sizes="(max-width: 590px) 100vw, 590px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Selain cumi oseng cabai, bagi pecinta pedas juga bisa mencoba resep cumi basah
      balado. Rasa pedasnya bisa disesuaikan dengan selera. Selain itu cumi basah
      balado ini memiliki kuah sehingga akan membangkitkan selera makan.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Bahan Membuat Cumi Basah Balado</span>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Cumi basah 500 gr</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Lemon 1 buah</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Air mineral 150 ml</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Bawang merah 4 siung</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Bawang putih 2 siung</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Cabai merah 100 gr</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Kemiri 2 butir</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Tomat 1 buah</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Jahe 2 cm</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Daun salam 2 lbr</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Garam secukupnya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Gula pasir 2 sdm</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Penyedap rasa secukupnya</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>Cara Membuat Cumi Basah Balado</span>
  </p>
  <ol>
    <li>
      <span style={{ fontWeight: 400 }}>
        Bersihkan cumi terlebih dahulu dan pastikan bahwa tintanya sudah benar-benar
        hilang. Kemudian tambahkan perasan lemon dan diamkan beberapa saat hingga
        bau amis dari cuminya berkurang atau hilang.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Sembari menunggu cumi, buatlah bumbu dengan mencampurkan semua bahan kecuali
        jahe, ulek hingga halus.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Geprek jahe lalu masukkan ke dalam ulekan bumbu yang sudah halus.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Siapkan wajan dan beri minyak secukupnya.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Tumis bumbu halus tadi hingga mengeluarkan aroma yang wangi. Tambahkan daun
        salam di atasnya.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Setelah itu tuangkan air sebanyak 150 ml. Masak hingga mendidih.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Setelah air mendidih kemudian masukkan cumi basah ke dalamnya. Tunggulah
        hingga air sedikit menyusut kemudian masukkan garam, gula dan penyedap rasa.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}>
        Apabila air di dalam wajan sudah berkurang dan rasanya sudah sesuai selera,
        cumi basah balado bisa diangkat dan disajikan dalam piring.
      </span>
    </li>
  </ol>
  <h5 style={{ textAlign: "center" }}>
    <span style={{ fontWeight: 400 }}>
      <strong>Resep Gulai Cumi Isi Tahu</strong>&nbsp;
    </span>
  </h5>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3145"
      src="./index_files/images-13.jpg"
      alt=""
      width="680"
      height="451"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-13.jpg         680w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-13-300x199.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-13-250x166.jpg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-13-600x398.jpg 600w
      "
      sizes="(max-width: 680px) 100vw, 680px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Gulai </span>
    <a target="_blank" href="https://www.fimela.com/lifestyle-relationship/read/3811155/resep-cumi-goreng-bumbu-bawang-ketumbar-enak-gurih">
      <span style={{ fontWeight: 400 }}>cumi</span>
    </a>
    <span style={{ fontWeight: 400 }}>
      isi tahu sering kita jumpai di rumah masakan Padang. Rasanya yang sedap memang
      sangat menggugah selera. Dan kita pun bisa membuatnya sendiri di rumah.
      Berikut resepnya.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Bahan-Bahan</span>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>3 ekor cumi ukuran sedang</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>2 potong tahu</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 butir telur ayam</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>2 lembar daun salam</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 batang serai</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>Bumbu Halus</span>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>6 butir bawang merah</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>3 siung bawang putih</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>2 buah tomat ukuran kecil</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>4 buah cabai merah</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>3 butir kemiri</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 ruas jahe</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 ruas kunyit</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 sdt ketumbar</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        garam, merica bubuk, dan gula secukupnya
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>air secukupnya</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>Cara Membuat</span>
  </p>
  <ol>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Cuci bersih cumi, buang isinya, dan kupas kulit arinya.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Untuk isian cumi, campur tahu dan telur dengan sedikit garam, lumatkan.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Masukkan bahan isian ke dalam perut cumi. Ikat bagian yang terbuka dengan
        tusuk gigi.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Tumis bumbu halus dengan daun salam dan serai sampai harum. Tambahkan garam,
        gula, merica bubuk, dan air secukupnya. Tes rasa. Biarkan sampai mendidih.
      </span>
    </li>
    <li>
      <span style={{ fontWeight: 400 }}> &nbsp; &nbsp; </span>
      <span style={{ fontWeight: 400 }}>
        Masukkan cumi ke dalam panci. Masak hingga empuk.
      </span>
    </li>
  </ol>
  <h5 style={{ textAlign: "center" }}>
    <strong>Resep Cumi Lada Hitam&nbsp;</strong>
  </h5>
  <p style={{ textAlign: "center" }}>
    <img
      className="alignnone size-full wp-image-3146"
      src="./index_files/images-14.jpg"
      alt=""
      width="714"
      height="410"
      srcset="
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-14.jpg         714w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-14-300x172.jpg 300w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-14-250x144.jpg 250w,
        https://web.archive.org/web/20200315131934im_/http://tumbasin.id/wp-content/uploads/2019/11/images-14-600x345.jpg 600w
      "
      sizes="(max-width: 714px) 100vw, 714px"
    />
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>
      Cumi sangat lezat diolah jadi berbagai masakan yang spesial. Nah, kali ini
      Vemale akan coba hadirkan satu resep cumi super sederhana yang kamu bisa
      praktekkan.
    </span>
  </p>
  <p>
    <span style={{ fontWeight: 400 }}>Bahan:</span>
  </p>
  <ul>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>100 gram cumi buang tintanya</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 buah cabai merah</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1 buah cabai hijau</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>1/2 buah bawang bombay</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>2 siung bawang putih</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>80 gram saus lada hitam</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>4 sdm minyak goring</span>
    </li>
  </ul>
  <p>
    <span style={{ fontWeight: 400 }}>Cara Membuat:</span>
  </p>
  <ol>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Iris bawang bombay, cabai, dan cincang bawang putih.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Potong cumi dan sisihkan.</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Panaskan minyak lalu masukkan bawang dan cabai.
      </span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Tumis sampai harum.</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Masukkan cumi.</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>Tambahkan saus lada hitam.</span>
    </li>
    <li style={{ fontWeight: 400 }}>
      <span style={{ fontWeight: 400 }}>
        Masak sebentar lalu angkat dan sajikan.
      </span>
    </li>
  </ol>
  <p>
    <span style={{ fontWeight: 400 }}>
      Nah itulah beberapa resep cumi yang bisa diolah sesuai dengan selera dan
      kesukaan. resep tersebut sangat mudah untuk dibuat dirumah dan harga
      bahan-bahannya sangatlah terjangkau. Selain lebih sehat karena memasak
      sendiri, tentu akan memunculkan rasa kepuasan ketika bisa memasaknya.
    </span>
  </p>`,
  },
];
