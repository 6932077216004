import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { blogs } from "../data/blogs";
import Footer from "./component/Footer";
import Header from "./component/Header";

function AllBlogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="page" className="hfeed site">
      <Header />

      <div id="content" className="site-content no-slider" style={{ paddingTop: 107 }}>
        <div className="mid-content clearfix">
          <section id="primary" className="content-area">
            <main id="main" className="site-main" role="main">
              <header className="page-header">
                <h1 className="page-title">Category: Blog</h1>
              </header>

              {blogs.map((blog, index) => (
                <article
                  key={index}
                  id="post-3558"
                  className="article-wrap post-3558 post type-post status-publish format-standard has-post-thumbnail hentry category-blog"
                >
                  <div className="entry-thumb">
                    <Link to={`/blog/${blog.id}`}>
                      <img src={blog.image} alt={blog.title} />
                    </Link>
                  </div>
                  <header className="entry-header">
                    <div className="entry-meta">
                      <span className="posted-on">
                        <time className="entry-date published" datetime={blog.created_at}>
                          <span className="posted-day">
                            {moment(new Date(blog.created_at)).format("DD")}
                          </span>
                          <span className="posted-month">
                            {moment(new Date(blog.created_at)).format("MMM")}
                          </span>
                        </time>
                      </span>
                    </div>
                    <h2 className="entry-title">
                      <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
                    </h2>
                  </header>

                  <div
                    dangerouslySetInnerHTML={{ __html: blog.content }}
                    className="entry-content"
                  ></div>

                  <footer className="entry-footer">
                    <span className="cat-links">
                      <i className="fa fa-folder-open"></i>Posted in Blog
                    </span>

                    <span className="comments-link">
                      <span>
                        <i className="fa fa-comments"></i>Leave a comment
                      </span>
                    </span>
                  </footer>
                </article>
              ))}
            </main>
          </section>

          <div id="secondary" className="widget-area" role="complementary">
            <aside id="search-2" className="widget widget_search">
              <form role="search" method="get" className="search-form" action="/">
                <label>
                  <span className="screen-reader-text">Search for:</span>
                  <input
                    type="search"
                    className="search-field"
                    placeholder="Search …"
                    readOnly
                    name="s"
                  />
                </label>
                <input type="submit" className="search-submit" value="Search" />
              </form>
            </aside>
            <aside id="recent-posts-2" className="widget widget_recent_entries">
              {" "}
              <h2 className="widget-title">
                <span>Recent Posts</span>
              </h2>{" "}
              <ul>
                {blogs.map((blog, index) => (
                  <li key={index}>
                    <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
                  </li>
                ))}
              </ul>
            </aside>
            <aside id="recent-comments-2" className="widget widget_recent_comments">
              <h2 className="widget-title">
                <span>Recent Comments</span>
              </h2>
              <ul id="recentcomments"></ul>
            </aside>
            <aside id="categories-2" className="widget widget_categories">
              <h2 className="widget-title">
                <span>Categories</span>
              </h2>
              <ul>
                <li className="cat-item cat-item-21">
                  <Link to={`/#section-265`}>Belanja</Link>
                </li>
                <li className="cat-item cat-item-23 current-cat">
                  <li className="cat-item cat-item-21">
                    <Link to={`/blogs`}>Blog</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-19">
                  <li className="cat-item cat-item-21">
                    <Link to={`/`}>Home</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-20">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-78`}>Layanan Kami</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-22">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-79`}>Promo</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-24">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-88`}>Testimonial</Link>
                  </li>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AllBlogs;
