import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";

function TermOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page" className="hfeed site">
        <Header />

        <div id="content" className="site-content no-slider" style={{ marginTop: 80 }}>
          <div className="mid-content clearfix">
            <div id="primary" className="content-area">
              <main id="main" className="site-main" role="main">
                <article
                  id="post-3474"
                  className="article-wrap post-3474 post type-post status-publish format-standard has-post-thumbnail hentry category-blog"
                >
                  <div className="entry-content">
                    <h1 style={{ textAlign: "center" }}>Syarat Penggunaan</h1>
                    <br></br>
                    <p style={{ textAlign: "justify" }}>
                      Mohon untuk membaca syarat dan ketentuan (“Syarat dan Ketentuan”) yang
                      tertulis di bawah ini secara seksama sebelum memesan Barang atau menggunakan
                      Layanan dari Situs ini. Untuk menggunakan Layanan kami, Anda harus berumur 21
                      tahun atau lebih. Apabila Anda berumur di bawah 21 tahun, maka anda
                      membutuhkan izin dari orang tua atau wali anda. Dengan tetap mengakses Situs
                      ini dan memesan Barang atau Layanan dari Situs ini melalui telepon atau
                      aplikasi telepon genggam kami, Anda setuju untuk terikat dengan Syarat dan
                      Ketentuan ini.
                    </p>
                    <br></br>
                    <ol>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Pendahuluan</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p>
                              Tumbasin adalah sebuah layanan one demand service yang menghubungkan
                              pasar tradasional dengan pembeli melalui sebuah aplikasi. OLEH KARENA
                              ITU PARA PIHAK SEPAKAT ATAS KETENTUAN DI BAWAH INI:
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Definisi</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.1 “Perjanjian” yaitu perjanjian ini (“Syarat dan Ketentuan”),
                              Kebijakan Privasi, setiap bentuk lainnya, dan cara pembayaran;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.2 “Aplikasi” yaitu aplikasi yang dapat dipasang pada telepon genggam
                              dari Tumbasin.id;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.3 “Pengiriman” yaitu jasa pengiriman yang disediakan oleh Pedagang,
                              pedagang disini adalah pedagang Pasar Tradisional yang bekerja sama
                              atau pihak ketiga yang bekerja sama dengan Tumbasin.id;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.4 “Barang” yaitu produk konsumen yang dijual secara eceran oleh
                              Pedagang. Namun demikian, Tumbasin mempunyai wewenang tersendiri untuk
                              tidak memasukkan Barang tersebut pada Platform;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.5 “Jam Operasional” yaitu waktu dan hari pada saat Pasar Tradisional
                              terbuka untuk umum;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.6 “Pedagang yang Bekerja Sama” atau “Pedagang” adalah pihak ketiga,
                              yang bekerja sama dengan Tumbasin untuk memasok Barang sehubungan
                              dengan Layanan;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.7 “Platform” yaitu www.Tumbasin.id, sebuah properti berupa domain
                              internet yang terdaftar dan setiap aplikasi telepon genggam atau web
                              yang menyediakan akses yang sama;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.8 “Kebijakan Privasi” yaitu perjanjian yang ditunjukkan pada Situs
                              ini, mengatur cara kami mengumpulkan dan menyimpan data;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.9 Layanan” yaitu setiap layanan yang kami sediakan dan dapat Anda
                              minta melalui Situs atau Aplikasi kami;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.10 “Situs” adalah www.Tumbasin.id atau sub-domain lainnya yang kami
                              gunakan untuk menawarkan Barang dan Layanan kami;
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              2.11 “Anda”, “Kalian”, “Konsumen” yaitu Anda, seseorang yang mengakses
                              Situs ini atau Aplikasi untuk memesan Barang dan menggunakan Layanan
                              dari Situs atau Aplikasi atau cara lainnya untuk memesan dari
                              Tumbasin.id.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Pemesanan</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              3.1 Dengan melakukan pemesanan Barang melalui Situs atau Aplikasi
                              Tumbasin.id berarti Anda memesan produk dari Pedagang yang Bekerja
                              Sama (bukan dari Tumbasin.id); Tumbasin.id hanya sebuah penyedia
                              Platform yang menyediakan Layanan bagi Konsumen untuk memesan Barang.
                              Dalam hal terdapat keluhan dari Konsumen, Tumbasin.id melalui layanan
                              pelanggan akan menyampaikan keluhan tersebut kepada Pedagang yang
                              Bekerja Sama dan mencari solusi yang terbaik bagi Konsumen.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              3.2 Setiap informasi yang Anda berikan kepada Tumbasin.id hanya
                              digunakan sehubungan dengan Layanan, Tumbasin.id menjamin bahwa
                              informasi tersebut tidak akan dipindahkan, diberikan, atau
                              diinformasikan kepada pihak ketiga lainnya, kecuali yang diatur secara
                              spesifik dalam Syarat dan Ketentuan ini atau Kebijakan Privasi.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              3.3 Pada saat memesan melalui Situs ini Anda diminta untuk memberikan
                              alamat e-mail dan sandi. Anda harus memastikan bahwa informasi
                              mengenai hal tersebut aman dan Anda tidak memberikan informasi
                              tersebut kepada pihak ketiga.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              3.4 Barang yang dikirim adalah untuk kebutuhan Anda dan tidak untuk
                              dijual. Anda menjamin bahwa Anda tidak bertindak sebagai agen untuk
                              pihak ketiga.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              3.5 Anda menjamin bahwa Anda cakap secara hukum untuk melakukan
                              pemesanan atas Barang yang disediakan melalui Situs atau Aplikasi.
                              Anda setuju untuk memberikan identitas yang valid sebelum membuat
                              pesanan. Anda pun setuju untuk menunjukkan identitas yang valid,
                              apabila diminta pada saat pengiriman untuk membuktikan identitas Anda.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              3.6 Kami akan melakukan seluruh tindakan yang diperlukan, sejauh
                              kemampuan kami, untuk menjaga data pemesanan dan pembayaran Anda,
                              namun demikian, apabila tidak terdapat kesalahan dari bagian kami,
                              kami tidak dapat dianggap bertanggung jawab untuk setiap kerugian yang
                              mungkin Anda terima apabila pihak ketiga melakukan tindakan tanpa
                              persetujuan untuk mengakses setiap data yang Anda berikan pada saat
                              mengakses atau memesan melalui Situs kecuali terbukti lain.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Harga dan Pembayaran</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              4.1 Seluruh harga yang terdaftar pada Situs atau Aplikasi yang
                              berdasarkan informasi dari Pedagang yang Bekerja Sama adalah benar
                              pada saat dipublikasikan. Namun demikian, harga tersebut dapat berubah
                              karena alasan bisnis. Sebagai penyedia platform, Tumbasin.id akan
                              mengubah harga yang terdaftar segera setelah terjadinya perubahan oleh
                              Pedagang.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              4.2 Seluruh harga yang terdaftar untuk pengiriman oleh pihak ketiga
                              yang bekerja sama dengan Tumbasin.id yang tercantum pada Situs atau
                              Aplikasi adalah benar pada saat publikasi. Namun demikian harga
                              tersebut dapat berubah karena alasan bisnis. Sebagai penyedia
                              platform, Tumbasin.id akan mengubah harga yang terdaftar setelah
                              terjadinya perubahan oleh pihak ketiga tersebut.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              4.3 Total harga untuk Pengiriman, Barang atau Layanan yang dipesan,
                              termasuk biaya pengiriman dan biaya lainnya, akan tercantum pada Situs
                              pada saat Anda menempatkan pesanan Anda di proses periksa. Harga yang
                              harus dibayar oleh Konsumen merupakan harga yang tercantum pada saat
                              Konsumen berada di proses periksa dari Situs atau Aplikasi. Perubahan
                              terhadap harga yang terdaftar tidak dapat terjadi pada saat Konsumen
                              berada dalam proses periksa dari Situs atau Aplikasi.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              4.4 Anda sepakat untuk memberikan kuasa kepada Tumbasin.id untuk
                              membayar pada saat barang sudah sampai di tangan Anda.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              4.5 Seluruh pembayaran harus dilakukan untuk seluruh Barang yang
                              dikirim dan Layanan yang disediakan. Pembayaran harus dilakukan
                              berdasarkan pilihan yang disediakan Tumbasin.id pada Situs atau
                              Aplikasi.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Kebijakan Pengiriman</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.1 Waktu pengiriman yang disediakan pada saat pemesanan hanya
                              perkiraan dan dapat berubah. Waktu pengiriman yang dapat dipilih
                              adalah dalam rentang waktu 1-3 jam pada hari yang sama dengan hari
                              pemesanan dan pada hari berikutnya. Jumlah pengiriman barang pada
                              setiap waktu pengiriman adalah terbatas, hal ini berarti kami tidak
                              dapat menjamin bahwa Anda akan menerima pengiriman sesuai dengan waktu
                              yang Anda inginkan. Keterbatasan tersebut akan diberitahukan di Situs
                              atau Aplikasi, dan Anda dapat memilih waktu pengiriman yang masih
                              tersedia.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.2 Tumbasin.id akan melakukan pengiriman Barang tanpa berkerjasama
                              dengan pihak ketiga. Kami akan berupaya sebaik mungkin untuk
                              memastikan bahwa pengiriman dilakukan dalam rentang waktu 1-3 jam
                              setelah Anda melakukan proses periksa.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.3 Namun demikian, Anda dapat menghubungi Tumbasin.id jika Anda tidak
                              menerima Barang dalam rentang waktu yang sudah ditetapkan oleh pihak
                              kami. Tumbasin.id akan memfasilitasi permasalahan Anda untuk menemukan
                              solusi terbaik dalam situasi tersebut.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.4 Pengiriman hanya tersedia selama Jam Operasional dari Pasar
                              Tradisional yang Bekerja Sama.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.5 Barang akan diantar ke alamat yang Anda tentukan pada saat
                              pemesanan.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.6 Dalam keadaan tertentu apabila terjadi keterlambatan pengiriman,
                              atas kebijakan sendiri, Tumbasin.id dapat tidak mengenakan biaya
                              pengiriman terhadap Konsumen.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.7 Semua risiko atas Barang beralih kepada Anda pada saat Pengiriman.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.8 Jika Anda tidak dapat menerima Barang yang diantar kepada Anda
                              pada saat Pengiriman, atau kami tidak dapat mengantarnya pada waktu
                              yang sudah ditetapkan dikarenakan kesalahan Anda dalam memberikan
                              informasi yang cukup dan instruksi yang tepat, maka Barang tersebut
                              dianggap telah diantar kepada Anda dan semua risiko dan tanggung jawab
                              atas Barang tersebut beralih pada Anda. Setiap penyimpanan, asuransi
                              dan biaya/pengeluaran lain yang kami tanggung sebagai bagian dari
                              ketidakmampuan melakukan pengiriman karena kesalahan Anda, menjadi
                              tanggung jawab Anda dan Anda harus memberikan kompensasi yang
                              diperlukan kepada Tumbasin.id apabila ada. Namun demikian, hal
                              tersebut tidak berlaku apabila Anda sudah menghubungi Tumbasin.id
                              melalui e-mail, telepon, atau layanan pelanggan.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.9 Anda harus memastikan bahwa pada saat Pengiriman Barang,
                              pengaturan yang diperlukan termasuk akses pengiriman sudah tersedia
                              demi keamanan pengiriman Barang tersebut. Kami tidak bertanggung jawab
                              untuk setiap kerusakan, biaya dan pengeluaran yang timbul terhadap
                              Barang tersebut atau tempat-tempat pada saat hal ini terjadi sebagai
                              hasil atas kesalahan Anda dalam memberikan akses dan situasi yang
                              memadai untuk pengiriman.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.10 Pedagang yang Bekerja Sama dan/atau Tumbasin.id tidak bertanggung
                              jawab kepada Anda atas kerugian, kewajiban, biaya, kerusakan, denda,
                              atau pengeluaran yang timbul dari keterlambatan pengiriman barang,
                              kecuali jika terbukti bahwa keterlambatan tersebut diakibatkan oleh
                              kesalahan Pedagang yang Bekerja Sama dan/atau Tumbasin.id.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              5.11 Mohon perhatikan bahwa pengiriman mungkin tidak dapat dilakukan
                              ke beberapa lokasi. Dalam hal ini, kami akan memberikan pemberitahuan
                              terlebih dahulu kepada Anda dan membatalkan pemesanan Anda.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Penolakan dan Pembatalan</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              6.1 Anda memahami bahwa pada saat pemesanan, Barang mungkin tidak
                              tersedia dikarenakan beberapa alasan dari Pedagang yang Bekerja Sama.
                              Dalam hal ini, Tumbasin.id akan memberitahu Anda melalui layanan
                              pelanggan kami (melalui e-mail atau telepon) bahwa barang yang Anda
                              pesan tidak tersedia, dan akan mengembalikan setiap pembayaran yang
                              telah Anda lakukan dalam waktu barang sudah Anda terima atau setelah
                              transaksi, tergantung pada cara pembayaran Anda.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Kebijakan Pengembalian</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              7.1 Tumbasin.id dan Pedagang yang Bekerja Sama akan memberikan
                              informasi dan penjelasan yang akurat untuk setiap produk, namun
                              apabila Anda menerima produk-produk yang Anda anggap tidak dapat
                              diterima, Anda berhak untuk menolak produk tersebut, dengan memberikan
                              alasan mengapa produk tersebut tidak dapat diterima. Kami akan
                              mempertimbangkan keberatan Anda dan mengembalikan pembayaran atas
                              produk tersebut dalam waktu 1 (satu) hari kerja setelah transaksi,
                              tergantung pada cara pembayaran Anda.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              7.2 Apabila atas pertimbangan lain, Anda tidak puas dengan pelayanan
                              yang kami berikan, Anda dapat memberikan penilaian atas pelayanan kami
                              melalui Situs atau Aplikasi. Berdasarkan kebijakan kami, Anda dapat
                              menerima pengembalian uang dari sebagian biaya Layanan yang
                              disediakan.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Informasi</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              8.1 Pada saat kami meminta informasi dari Anda untuk memastikan
                              Pengiriman, Anda setuju untuk memberikan informasi yang akurat dan
                              lengkap
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              8.2 Anda sepakat untuk mengizinkan kami untuk menggunakan, menyimpan
                              atau memproses informasi pribadi Anda yang diperlukan untuk
                              menyediakan Pengiriman, Barang atau Layanan kepada Anda dan untuk
                              tujuan pemasaran dan pengendalian kredit (“Penggunaan”). Penggunaan
                              ini dapat berupa dibukanya informasi pribadi Anda pada pihak ketiga
                              tertentu yang kami pilih, setiap kali kami meyakini bahwa layanan yang
                              ditawarkan oleh pihak ketiga tersebut penting bagi Anda atau ketika
                              hal ini diwajibkan oleh hukum, atau dalam rangka Pengiriman Makanan,
                              Barang atau Layanan untuk Anda. Informasi lebih lanjut dapat dilihat
                              pada Kebijakan Privasi kami.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              8.3 Anda berhak untuk meminta salinan informasi pribadi Anda yang kami
                              miliki. Silakan hubungi kami ketika Anda ingin meminta informasi ini.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Keluhan</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              Tumbasin.id akan berusaha sebaik mungkin untuk memfasilitasi keluhan
                              Anda dengan menyampaikan keluhan tersebut kepada Pedagang yang Bekerja
                              Sama atau Pedagang. Kami menanggapi keluhan pelanggan secara serius
                              dan mengusahakan untuk menanggapi keluhan Anda sesegera mungkin.
                              Segala keluhan ditujukan pada tumbassemarang@gmail.com atau melalui
                              layanan pelanggan kami secara langsung.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Batasan Tanggung Jawab</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.1 Kami berusaha sebaik mungkin untuk memastikan bahwa informasi
                              pada Situs atau Aplikasi adalah benar dan bebas dari kesalahan pada
                              setiap saat. Terlepas dari usaha maksimal kami, kami tidak dapat
                              menjamin bahwa pada Situs atau Aplikasi akan selalu bebas dari
                              kesalahan dan penggunaannya selalu sesuai dengan tujuan, tepat waktu
                              dan setiap kesalahan akan dikoreksi, dan situs atau server bebas dari
                              virus atau bug atau menunjukkan keseluruhan fungsi, akurasi,
                              kehandalan Situs tersebut dan kami tidak dapat menjamin terhadap
                              hal-hal tersebut, baik secara eksplisit atau implisit, berkaitan
                              dengan kesesuaian dengan tujuan, atau akurasi.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.2 Dengan menyetujui ketentuan penggunaan ini Anda sepakat untuk
                              melepaskan kami dari tanggung jawab apapun yang timbul dari penggunaan
                              informasi dari pihak ketiga oleh Anda, atau konsumsi Barang dari
                              Pedagang yang Bekerja Sama oleh Anda.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.3 Kami melepaskan setiap dan seluruh tanggung jawab kepada Anda
                              terhadap pelaksanaan Pengiriman, pemasokan Barang dan Layanan selama
                              diperbolehkan oleh peraturan perundang-undangan yang berlaku. Hal ini
                              tidak mempengaruhi hak Anda berdasarkan hukum sebagai pelanggan.
                              Apabila kami menemukan bahwa kami bertanggung jawab atas kehilangan
                              atau kerugian apapun kepada Anda, tanggung jawab tersebut terbatas
                              pada nilai yang telah Anda bayar untuk Barang atau Layanan yang
                              relevan. Kami tidak dapat menerima tanggung jawab untuk setiap
                              kehilangan, kerugian atau pengeluaran apapun, termasuk setiap
                              kehilangan langsung atau tidak langsung seperti kehilangan keuntungan
                              pada Anda, apapun alasannya. Pembatasan tanggung jawab ini tidak
                              berlaku terhadap kecelakaan atau kematian pribadi yang timbul secara
                              langsung atas kelalaian kami.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.4 Kami tidak menerima tanggung jawab apapun untuk setiap
                              keterlambatan, kegagalan, kesalahan atau kelalaian atau kerugian atas
                              informasi yang dikirimkan, virus atau kontaminasi lainnya atau hal-hal
                              yang merusak yang dikirimkan kepada sistem komputer milik pelanggan
                              melalui Situs atau Aplikasi kami.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.5 Kami tidak bertanggung jawab untuk setiap kegagalan atau
                              keterlambatan dalam melakukan Layanan atau mengirimkan Barang yang
                              mana kegagalan tersebut timbul sebagai akibat dari setiap tindakan
                              atau kelalaian, yang di luar kendali kami seperti segala kejadian luar
                              biasa dan tidak dapat dihindari yang disebabkan secara langsung dan
                              khusus oleh kekuatan alam yang tidak dapat diantisipasi, dikendalikan,
                              atau dicegah, dengan tindakan kehati-hatian, ketelitian, dan
                              perhatian, termasuk namun tidak terbatas pada: perang, kerusuhan,
                              huru-hara, perintah undang-undang atau pemerintah, peraturan,
                              ketentuan atau arahan dan tindakan dari pihak ketiga.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.6 Apabila kami dikontrak untuk memberikan pesanan yang identik atau
                              mirip kepada lebih dari satu Konsumen dan tidak dapat memenuhi seluruh
                              kewajiban kami kepada Anda dengan alasan Keadaan Kahar, kami dapat
                              menentukan berdasarkan wewenang kami, pesanan mana yang akan kami
                              penuhi dan sejauh mana akan kami lakukan. Namun, kami akan memberikan
                              pemberitahuan terlebih dahulu kepada pihak-pihak yang telah memesan
                              tetapi tidak dapat dipenuhi pesanannya dan Anda berhak atas kebijakan
                              pengembalian sebagaimana dijelaskan pada Bagian 7.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.7 Produk yang kami jual hanya untuk penggunaan perorangan lokal dan
                              digunakan untuk konsumen. Dengan demikian, kami tidak bertanggung
                              jawab atas setiap kehilangan yang tidak langsung, kehilangan karena
                              suatu sebab, kehilangan data, kehilangan pendapatan atau keuntungan,
                              kehilangan atau kerusakan pada properti dan/atau kerugian atas klaim
                              dari pihak ketiga yang timbul atas penggunaan Situs atau Aplikasi atau
                              untuk setiap produk atau layanan yang dibeli dari kami.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.8 Kami telah mengambil langkah-langkah untuk mencegah penipuan
                              melalui Internet dan memastikan setiap data yang dikumpulkan dari Anda
                              disimpan seaman mungkin. Namun, kami tidak bertanggung jawab dalam hal
                              terjadi peristiwa yang kemungkinannya sangat kecil terjadi pada server
                              komputer atau dari pihak ketiga.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.9 Dalam hal kami memiliki keyakinan bahwa terdapat penyalahgunaan
                              voucher dan/atau kode diskon atau terdapat hal yang menimbulkan
                              penipuan, Tumbasin.id dapat melakukan pemblokiran terhadap konsumen
                              dengan segera dan berhak untuk menolak memberikan layanan di masa
                              nanti terhadap konsumen tersebut. Kemudian, apabila terdapat
                              penyalahgunaan voucher atau kode diskon, kami berhak untuk mendapat
                              kompensasi dari setiap dan seluruh pelaku yang melakukan hal tersebut.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              10.10 Penawaran merupakan sesuatu yang menjadi kewenangan kami dan
                              dapat ditarik kapanpun dengan pemberitahuan sebelumnya kepada calon
                              Konsumen.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: "bold" }}>
                        <b>Ketentuan Umum</b>
                        <ul>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.1 Seluruh harga adalah dalam Rupiah (IDR).
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.2 Kami dapat melakukan sub-kontrak terhadap beberapa bagian atau
                              bagian-bagian dari Layanan atau Barang yang kami sediakan kepada Anda
                              dari waktu ke waktu.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.3 Kami akan mempublikasikan setiap perubahan yang materiil atau
                              subtantif dari Syarat dan Ketentuan pada Situs atau Aplikasi, dan Anda
                              harus membaca perubahan tersebut secara teliti. Jika Anda melanjutkan
                              untuk menggunakan dan mengakses Situs atau Aplikasi setiap setelah
                              Syarat dan Ketentuan yang diubah dipublikasikan, maka akan dianggap
                              sebagai persetujuan Anda terhadap Syarat dan Ketentuan yang diubah.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.4 Setiap keberatan atas Syarat dan Ketentuan yang diubah harus
                              diberikan kepada Tumbasin.id dalam waktu 7 (tujuh) hari setelah
                              perubahan tersebut dipublikasikan pada Situs atau Aplikasi.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.5 Anda tidak dapat untuk menggunakan atau meluncurkan sistem
                              otomatis atau program sehubungan dengan Situs atau Aplikasi Kami atau
                              fungsi pemesanan online-nya.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.6 Anda tidak dapat mengumpulkan atau mengambil informasi mengenai
                              identitas pribadi dari Situs atau Aplikasi, penggunaan sistem
                              komunikasi yang disediakan oleh Situs atau Aplikasi untuk dikumpulkan
                              secara komersil, pengumpulan untuk segala alasan oleh pengguna Situs
                              atau Aplikasi sehubungan dengan pendaftarannya di Situs atau Aplikasi,
                              atau mempublikasikan atau mendistribusikan setiap vouchers atau kode
                              sehubungan dengan situs atau mengumpulkan atau pengambilan atau
                              melakukan hack terhadap Situs atau Aplikasi.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.7 Syarat dan Ketentuan bersama dengan Kebijakan Privasi, setiap
                              bentuk lainnya dan cara pembayaran merupakan satu kesatuan perjanjian
                              antara Anda dan Kami. Tidak ada ketentuan baik secara eksplisit atau
                              implisit yang akan menjadi bagian dari Perjanjian ini. Dalam hal
                              terdapat perbedaan antara Syarat dan Ketentuan ini dan setiap dari
                              ketentuan atau aturan pada Situs atau Aplikasi kecuali yang berkaitan
                              dengan masalah privasi, maka Syarat dan Ketentuan akan berlaku.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.8 Jika terdapat ketentuan atau keadaan pada Perjanjian yang menjadi
                              tidak valid, ilegal atau tidak dapat diterapkan, maka para pihak
                              dengan ini sepakat bahwa ketentuan atau kondisi tersebut akan dihapus
                              dan ketentuan lainnya dalam Perjanjian akan tetap berlaku tanpa
                              ketentuan atau keadaan tersebut.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.9 Syarat dan Ketentuan ini dan Perjanjian kami diatur dan dibuat
                              berdasarkan hukum Indonesia. Para pihak dengan ini sepakat untuk
                              memilih jurisdiksi Pengadilan Negeri Semarang.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.10 Tidak ada penundaan atau kegagalan untuk menerapkan hak atau
                              ganti rugi yang merupakan hak kami berdasarkan Perjanjian, yang akan
                              dianggap sebagai pengesampingan atas bagian kami terhadap hak atau
                              ganti rugi tersebut kecuali pengesampingan tersebut dikonfirmasi
                              secara tertulis.
                            </p>
                          </li>
                          <li style={{ fontWeight: 300 }}>
                            <p style={{ textAlign: "justify" }}>
                              11.11 Syarat dan Ketentuan ini dan kontrak (dan semua kewajiban non
                              kontraktual yang timbul atau sehubungan dengan itu) diatur dan dibuat
                              berdasarkan Hukum Indonesia. Kami dan Anda dengan ini secara
                              non-ekslusif memilih jurisdiksi kepaniteraan Pengadilan Negeri
                              Semarang. Seluruh kesepakatan, korespondensi dan kontak antara kita
                              harus dibuat berdasarkan Bahasa Indonesia.
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </article>
              </main>
            </div>

            {/* <div id="secondary" className="widget-area" role="complementary">
            <aside id="search-2" className="widget widget_search">
              <form role="search" method="get" className="search-form" action="/">
                <label>
                  <span className="screen-reader-text">Search for:</span>
                  <input
                    readOnly
                    type="search"
                    className="search-field"
                    placeholder="Search …"
                    name="s"
                  />
                </label>
                <input type="submit" className="search-submit" value="Search" />
              </form>
            </aside>{" "}
            <aside id="recent-posts-2" className="widget widget_recent_entries">
              {" "}
              <h2 className="widget-title">
                <span>Recent Posts</span>
              </h2>{" "}
              <ul>
                {blogs.map((blog, index) => (
                  <li key={index}>
                    <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
                  </li>
                ))}
              </ul>
            </aside>
            <aside id="recent-comments-2" className="widget widget_recent_comments">
              <h2 className="widget-title">
                <span>Recent Comments</span>
              </h2>
              <ul id="recentcomments"></ul>
            </aside>
            <aside id="categories-2" className="widget widget_categories">
              <h2 className="widget-title">
                <span>Categories</span>
              </h2>{" "}
              <ul>
                <li className="cat-item cat-item-21">
                  <Link to={`/#section-265`}>Belanja</Link>
                </li>
                <li className="cat-item cat-item-23 current-cat">
                  <li className="cat-item cat-item-21">
                    <Link to={`/blogs`}>Blog</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-19">
                  <li className="cat-item cat-item-21">
                    <Link to={`/`}>Home</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-20">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-78`}>Layanan Kami</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-22">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-79`}>Promo</Link>
                  </li>
                </li>
                <li className="cat-item cat-item-24">
                  <li className="cat-item cat-item-21">
                    <Link to={`/#section-88`}>Testimonial</Link>
                  </li>
                </li>
              </ul>
            </aside>
          </div> */}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default TermOfUse;
