import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { blogs } from "../data/blogs";
import { help } from "../data/help";
import Footer from "./component/Footer";
import Header from "./component/Header";

function Help() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page" className="hfeed site">
        <Header />

        <div id="content" className="site-content no-slider" style={{ marginTop: 80 }}>
          <div className="mid-content clearfix">
            <div id="primary" className="content-area">
              <main id="main" className="site-main" role="main">
                <article
                  id="post-3474"
                  className="article-wrap post-3474 post type-post status-publish format-standard has-post-thumbnail hentry category-blog"
                >
                  <div className="entry-content">
                    <h1 style={{ textAlign: "center" }}>Halaman Bantuan</h1>

                    {help.map((h, idx) => (
                      <div key={idx}>
                        <h4>{h.topic}</h4>
                        <ul>
                          {h.qna.map((content, i) => (
                            <li key={i}>
                              <b>{content.q}</b>
                              <p>
                                <span dangerouslySetInnerHTML={{ __html: content.a }}></span>
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Help;
