import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomerTestimoni from "./component/CustomerTestimoni";
import googlePlayImage from "../images/googleplay.png";
import appStoreImage from "../images/appstore.png";
import Header from "./component/Header";
import Footer from "./component/Footer";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="page" className="hfeed site">
      <Header />

      <div id="content" className="site-content no-slider">
        <section className="parallax-section clearfix default_template" id="section-473">
          <div className="mid-content">
            <h2>
              <span></span>
            </h2>

            <div className="parallax-content">
              <div className="page-content">
                <div
                  data-elementor-type="wp-post"
                  data-elementor-id="473"
                  className="elementor elementor-473"
                  data-elementor-settings="[]"
                >
                  <div className="elementor-inner">
                    <div className="elementor-section-wrap">
                      <section
                        className="elementor-element elementor-element-76ef925e elementor-section-height-min-height elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
                        data-id="76ef925e"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-no">
                          <div className="elementor-row">
                            <div
                              className="elementor-element elementor-element-21a575a4 elementor-column elementor-col-50 elementor-top-column"
                              data-id="21a575a4"
                              data-element_type="column"
                            >
                              <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                  <div
                                    className="elementor-element elementor-element-ec4d913 elementor-widget elementor-widget-image"
                                    data-id="ec4d913"
                                    data-element_type="widget"
                                    data-widget_type="image.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="elementor-image">
                                        <img
                                          width="509"
                                          height="720"
                                          src="./index_files/MOCKUP_12_NEW.png"
                                          className="attachment-large size-large"
                                          alt=""
                                          sizes="(max-width: 509px) 100vw, 509px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-60f2d88 elementor-column elementor-col-50 elementor-top-column"
                              data-id="60f2d88"
                              data-element_type="column"
                            >
                              <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                  <div
                                    className="elementor-element elementor-element-5e31cd7 elementor-widget elementor-widget-heading"
                                    data-id="5e31cd7"
                                    data-element_type="widget"
                                    data-widget_type="heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <h1 className="elementor-heading-title elementor-size-small">
                                        BELANJA KE PASAR TANPA KELUAR RUMAH?
                                      </h1>
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-element elementor-element-8d15816 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button"
                                    data-id="8d15816"
                                    data-element_type="widget"
                                    data-widget_type="button.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="elementor-button-wrapper">
                                        <div
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                          }}
                                        >
                                          <a
                                            href="https://play.google.com/store/apps/details?id=id.tumbasin.app"
                                            role="button"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={googlePlayImage}
                                              alt="google play"
                                              style={{ height: 65 }}
                                            />
                                          </a>                                          
                                          <a
                                            href="https://apps.apple.com/id/app/tumbasin-id/id1660676510"
                                            role="button"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={appStoreImage}
                                              alt="app store"
                                              style={{ height: 65 }}
                                            />
                                          </a>                                        
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-area"></div>
          </div>
        </section>

        <section className="parallax-section clearfix service_template" id="section-78">
          <div className="mid-content">
            <h2>
              <span>LAYANAN KAMI</span>
            </h2>

            <div className="parallax-content"></div>

            <div className="service-listing clearfix">
              <div className="clearfix service-list odd wow fadeInLeft" data-wow-delay="0.25s">
                <div className="service-image">
                  <img src="./index_files/online-shop-150x150.png" alt="PRAKTIS" />
                </div>

                <div className="service-detail">
                  <h3>PRAKTIS</h3>
                  <div className="service-content">
                    <p>Belanja ke Pasar menjadi lebih mudah. Nikmati Promo tiap bulanannya</p>
                  </div>
                </div>
              </div>

              <div className="clearfix service-list even wow fadeInRight" data-wow-delay="0.5s">
                <div className="service-image">
                  <img src="./index_files/time-and-date-150x150.png" alt="HEMAT WAKTU" />
                </div>

                <div className="service-detail">
                  <h3>HEMAT WAKTU</h3>
                  <div className="service-content">
                    <p>
                      Meluangkan waktu dengan keluarga menjadi lebih lama, urusan belanjamu kami
                      yang urus.
                    </p>
                  </div>
                </div>
              </div>

              <div className="clearfix"></div>

              <div className="clearfix service-list odd wow fadeInLeft" data-wow-delay="0.75s">
                <div className="service-image">
                  <img src="./index_files/guarantee-150x150.png" alt="GARANSI KUALITAS" />
                </div>

                <div className="service-detail">
                  <h3>GARANSI KUALITAS</h3>
                  <div className="service-content">
                    <p>
                      Jika kamu tidak puas dengan kualitas barang dari kami, kami <br />
                      akan menggantinya (Claim di hari yang sama).
                    </p>
                  </div>
                </div>
              </div>

              <div className="clearfix service-list even wow fadeInRight" data-wow-delay="1s">
                <div className="service-image">
                  <img src="./index_files/calendar-150x150.png" alt="ON DEMAND SERVICE" />
                </div>

                <div className="service-detail">
                  <h3>ON DEMAND SERVICE</h3>
                  <div className="service-content">
                    <p>
                      Batas waktu order sampai jam 08.00 pagi (diatas jam 08.00 pagi,
                      <br />
                      akan diantar besok pagi). Maksimal pengantaran
                      <br />
                      jam 11.00 siang.
                    </p>
                  </div>
                </div>
              </div>

              <div className="clearfix"></div>

              <div className="clearfix service-list odd wow fadeInLeft" data-wow-delay="1.25s">
                <div className="service-image">
                  <img src="./index_files/delivery-man-150x150.png" alt="ORDER MALAM HARI" />
                </div>

                <div className="service-detail">
                  <h3>ORDER MALAM HARI</h3>
                  <div className="service-content">
                    <p>Kamu bisa request jam pengantaran, jika Anda pesan pada hari sebelumnya.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="parallax-section clearfix default_template" id="section-265">
          <div className="mid-content">
            <h2>
              <span>PRODUK KAMI</span>
            </h2>

            <div className="parallax-content">
              <div className="page-content">
                <p></p>
                <div className="woocommerce columns-4 quick-sale">
                  <ul className="products columns-4">
                    <li
                      // style={{ backgroundColor: "#ffffff" }}
                      className="product type-product post-366 status-publish first instock product_cat-buah has-post-thumbnail sale taxable shipping-taxable purchasable product-type-simple"
                    >
                      <Link
                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                        to={`/?product=gulaku-premium/`}
                      >
                        <span className="onsale">Sale!</span>
                        <img
                          width="300"
                          height="300"
                          src="./index_files/gulaku_premium.jpg"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                        <h2 className="woocommerce-loop-product__title">Gulaku</h2>
                        <span className="price">
                          <del>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>14.000
                            </span>
                          </del>
                          <ins>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>11.000
                            </span>
                          </ins>
                        </span>{" "}
                      </Link>
                      {/* <Link
                        className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                        to={`/?add-to-cart=366`}
                      >
                        <span
                          data-quantity="1"
                          data-product_id="366"
                          data-product_sku=""
                          aria-label="Add “Pisang Kepok” to your cart"
                          rel="nofollow"
                        >
                          Add to cart
                        </span>
                      </Link> */}
                    </li>
                    <li className="product type-product post-297 status-publish instock product_cat-seafood product_tag-kerang product_tag-seafood has-post-thumbnail sale featured taxable shipping-taxable purchasable product-type-simple">
                      <Link
                        to={`/?product=bayam`}
                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                      >
                        {/* <span className="onsale">Sale!</span> */}
                        <img
                          width="300"
                          height="300"
                          src="./index_files/bayam.png"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                        <h2 className="woocommerce-loop-product__title">Bayam</h2>
                        <span className="price">
                          {/* <del>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>21.000
                            </span>
                          </del> */}
                          <ins>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>4.000
                            </span>
                          </ins>
                        </span>{" "}
                      </Link>
                      {/* <Link
                        to={`/?add-to-cart=297`}
                        className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                      >
                        <span
                          data-quantity="1"
                          data-product_id="297"
                          data-product_sku=""
                          aria-label="Add “Kerang Dara” to your cart"
                          rel="nofollow"
                        >
                          Add to cart
                        </span>
                      </Link> */}
                    </li>
                    <li className="product type-product post-295 status-publish instock product_cat-bumbu product_tag-bumbu product_tag-cabai has-post-thumbnail sale taxable shipping-taxable purchasable product-type-simple">
                      <Link
                        to={`/?product=cabai-merah-keriting`}
                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                      >
                        {/* <span className="onsale">Sale!</span> */}
                        <img
                          width="200"
                          height="200"
                          src="./index_files/Cabai_Merah_Keriting.png"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          sizes="(max-width: 200px) 100vw, 200px"
                        />
                        <h2 className="woocommerce-loop-product__title">Cabai Merah Keriting</h2>
                        <span className="price">
                          {/* <del>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>8.600
                            </span>
                          </del> */}
                          <ins>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>6.500
                            </span>
                          </ins>
                        </span>{" "}
                      </Link>
                      {/* <Link
                        to={`/?add-to-cart=295`}
                        className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                      >
                        <span
                          data-quantity="1"
                          data-product_id="295"
                          data-product_sku=""
                          aria-label="Add “Cabai Merah Keriting” to your cart"
                          rel="nofollow"
                        >
                          Add to cart
                        </span>
                      </Link> */}
                    </li>
                    <li className="product type-product post-277 status-publish last instock product_cat-bumbu product_tag-bawang-merah product_tag-bumbu-dapur has-post-thumbnail sale taxable shipping-taxable purchasable product-type-simple">
                      <Link
                        to={`/?product=cabai-setan`}
                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                      >
                        {/* <span className="onsale">Sale!</span> */}
                        <img
                          width="300"
                          height="300"
                          src="./index_files/Cabai_Rawit_Merah.png"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                        <h2 className="woocommerce-loop-product__title">Cabai Rawit Merah</h2>
                        <span className="price">
                          {/* <del>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>9.100
                            </span>
                          </del> */}
                          <ins>
                            <span className="woocommerce-Price-amount amount">
                              <span className="woocommerce-Price-currencySymbol">Rp</span>7.000
                            </span>
                          </ins>
                        </span>{" "}
                      </Link>
                      {/* <Link
                        to={`/?add-to-cart=277`}
                        className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                      >
                        <span
                          data-quantity="1"
                          data-product_id="277"
                          data-product_sku=""
                          aria-label="Add “Cabai Setan” to your cart"
                          rel="nofollow"
                        >
                          Add to cart
                        </span>
                      </Link> */}
                    </li>
                  </ul>
                </div>
                <p></p>
              </div>
            </div>

            <div className="content-area"></div>
          </div>
        </section>

        <section className="parallax-section clearfix portfolio_template" id="section-79">
          <div className="mid-content">
            <h2>
              <span>PROMO</span>
            </h2>

            <div className="parallax-content"></div>

            <div className="portfolio-listing clearfix">
              <Link
                to="/?promo=gratis-ongkirtelur-ayam"
                className="portfolio-list wow fadeInUp"
                data-wow-delay="0.15s"
              >
                <div className="portfolio-wrap">
                  <div className="portfolio-overlay">
                    <span>+</span>
                  </div>
                  <div className="portfolio-image">
                    <img
                      src="./index_files/photo_2023-01-11_10-17-10.jpg"
                      alt="Belanja dimanapun dan kapanpun"
                    />
                  </div>
                  <h3 style={{ backgroundColor: "rgba(241, 91, 93 )" }}>Flash Sale</h3>
                </div>
              </Link>
              <Link
                to="/?promo=gratis-telur-ayam"
                className="portfolio-list wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="portfolio-wrap">
                  <div className="portfolio-overlay">
                    <span>+</span>
                  </div>
                  <div className="portfolio-image">
                    <img
                      src="./index_files/photo_2023-01-11_10-17-14.jpg"
                      alt="Gratis Ongkos Kirim"
                    />
                  </div>
                  <h3 style={{ backgroundColor: "rgba(241, 91, 93)" }}>Promo Gulaku 1Kg</h3>
                </div>
              </Link>
              <Link
                to="/?promo=tebak-tebakan"
                className="portfolio-list wow fadeInUp"
                data-wow-delay="0.45s"
              >
                <div className="portfolio-wrap">
                  <div className="portfolio-overlay">
                    <span>+</span>
                  </div>
                  <div className="portfolio-image">
                    <img src="./index_files/voucher.jpg" alt="Gratis Telur Ayam 250 Gram" />
                  </div>
                  <h3 style={{ backgroundColor: "rgba(241, 91, 93)" }}>
                    Voucher Ongkir & Cashback
                  </h3>
                </div>
              </Link>
            </div>
          </div>
        </section>

        <section className="parallax-section clearfix action_template" id="section-80">
          <div className="mid-content">
            <div className="call-to-action">
              <div className="parallax-content">
                <div className="page-content">
                  <h2 style={{ textAlign: "center" }}>BELANJA SEKARANG DAN DAPATKAN PROMONYA</h2>
                  {/* <h4 style={{ textAlign: "center" }}>Banyak Promo di Bulan Ini</h4> */}
                  {/* <p>&nbsp;</p> */}
                  {/* <h5 style={{ textAlign: "center", fontSize: 20 }}>BELANJA SEKARANG</h5> */}
                  <div className="elementor-widget-container">
                    <div className="elementor-button-wrapper">
                      <div
                        style={{
                          margin: 0,
                          padding: 0,
                          position: "relative",
                        }}
                      >
                        <a
                          style={{ border: "none" }}
                          href="https://play.google.com/store/apps/details?id=id.tumbasin.app"
                          role="button"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={googlePlayImage} alt="google play" style={{ height: 65 }} />
                        </a>                        
                        <a
                          style={{ border: "none" }}
                          href="https://apps.apple.com/id/app/tumbasin-id/id1660676510"
                          role="button"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={appStoreImage} alt="app store" style={{ height: 65 }} />
                        </a>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="parallax-section clearfix blog_template" id="section-81">
          <div className="mid-content">
            <h2>
              <span>BLOG</span>
            </h2>
            <div className="parallax-content"></div>
            <div className="blog-listing clearfix">
              <Link to="/blog/1" className="blog-list wow fadeInDown" data-wow-delay="0.25s">
                <div className="blog-image">
                  <img
                    src="./index_files/Promo_00020-–-39-e1575124617270-480x300.png"
                    alt="Tumbasin Blog Competition 2019"
                  />
                </div>

                <div className="blog-excerpt">
                  <h3>Tumbasin Blog Competition 2019</h3>
                  <h4 className="posted-date">
                    <i className="fa fa-calendar"></i>November 30, 2019
                  </h4>
                  Hi Bloggers Semarang! Tumbasin mengadakan kontes menulis blog 2019 dan menangkan
                  hadiah jutaan rupiahnya. Terkadang kesibukan bekerja ataupun jarak tempuh yang
                  lumayan jauh membuat kita kesulitan untu... <br />
                  <span>
                    Read More&nbsp;&nbsp;<i className="fa fa-angle-right"></i>
                  </span>
                </div>
              </Link>
              <Link to={`/blog/2`} className="blog-list wow fadeInDown" data-wow-delay="0.5s">
                <div className="blog-image">
                  <img
                    src="./index_files/imagesa-480x300.jpg"
                    alt="ULASAN SINGKAT IKAN KERAPU DARI CIRI-CIRI SAMPAI HARGA IKAN KERAPU"
                  />
                </div>

                <div className="blog-excerpt">
                  <h3>ULASAN SINGKAT IKAN KERAPU DARI CIRI-CIRI SAMPAI HARGA IKAN KERAPU</h3>
                  <h4 className="posted-date">
                    <i className="fa fa-calendar"></i>November 27, 2019
                  </h4>
                  Tidak dapat dipungkiri bahwa ikan kerapu merupakan salah satu jenis ikan yang
                  laris dikonsumsi oleh masyarakat Indonesia. Bahkan, saat ini Anda akan mendapati
                  bahwa ada banyak negara di luar sana yang... <br />
                  <span>
                    Read More&nbsp;&nbsp;<i className="fa fa-angle-right"></i>
                  </span>
                </div>
              </Link>

              <Link to={`/blog/3`} className="blog-list wow fadeInDown" data-wow-delay="0.75s">
                <div className="blog-image">
                  <img
                    src="./index_files/images-37-480x300.jpg"
                    alt="Beberapa Manfaat Kopi Bagi Tubuh"
                  />
                </div>

                <div className="blog-excerpt">
                  <h3>Beberapa Manfaat Kopi Bagi Tubuh</h3>
                  <h4 className="posted-date">
                    <i className="fa fa-calendar"></i>November 26, 2019
                  </h4>
                  Kopi adalah sejenis minuman yang berasal dari proses pengolahan dan ekstraksi biji
                  tanaman kopi. Kata kopi sendiri berasal dari bahasa Arab: qahwah yang berarti
                  kekuatan, karena pada awalnya kopi digu... <br />
                  <span>
                    Read More&nbsp;&nbsp;<i className="fa fa-angle-right"></i>
                  </span>
                </div>
              </Link>
            </div>

            <div className="clearfix btn-wrap">
              <Link className="btn" to="/blogs">
                Read All
              </Link>
            </div>
          </div>
        </section>
        <CustomerTestimoni />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
